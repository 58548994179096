.about-2 {
    background: #F7F7F7;
  }
  
  .about-2 .checklist {
    padding-left: 40px;
  }
  
  .about-2 .checklist li {
    position: relative;
    margin-bottom: 15px;
    font-size: 16px;
    color: #000;
    font-family: "Source Sans Pro", sans-serif;
  }
  
  .about-2 .checklist li:before {
      position: absolute;
      margin-right: 12px;
      margin-left: -45px;
      content: "\f00c";
      font-family: "Font Awesome 5 free";
      font-weight: 900;
      opacity: 1;
      border-radius: 50%;
      padding: 5px 8px 0;
      font-size: 15px;
      width: 30px;
      height: 30px;
      background: #FAD481;
      color: #000;
  }