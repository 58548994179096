.status-box {
  /* height: 50px; */
  width: 115px;
  background-color: #D8FFE0;
  border-radius: 5px;
  flex-direction: row;
  padding: 5px;
}

.status-box-primary {
  /* height: 50px; */
  width: 115px;
  background-color: #DFEDF2;
  border-radius: 5px;
  flex-direction: row;
  padding: 5px;

}

.status-box-warn {
  /* height: 50px; */
  width: 115px;
  background-color: #FFEBCC;
  border-radius: 5px;
  flex-direction: row;
  padding: 5px;
}

.text {
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}
