.process .mb-3 h3 {

  width: 124px;
  height: 67px;
  left: 490px;
  top: 306px;


  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 67px;

  color: #000000;
}

.process Label h4 {
  width: 183px;
  height: 45px;

  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 45px;

  /* identical to box height */

  color: #000000;


  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.process .Control {
  box-sizing: border-box;

  width: 921px;
  height: 76px;

  background: #FBFBFB;
  border: 2px solid #979797;
  border-radius: 5px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.process .Control {
  box-sizing: border-box;

  width: 921px;
  height: 76px;

  background: #FBFBFB;
  border: 2px solid #979797;
  border-radius: 5px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.process .container .row .col-sm-6 .boxA {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 45px;
  gap: 14px;

  width: 196px;
  height: 56px;

  background: rgba(250, 212, 129, 0.5);
  border-radius: 14px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.process .container .row .col-sm-6 .form-check-input {
  /* Auto layout */
  justify-items: center;
}

.process .container .row .col-sm-6 .boxA .form-check-input {

  background: #FF844B;
}

.process .container .row .col-sm-6 label {
  width: 106px;
  height: 24px;

  /* Button / Button lg */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  /* identical to box height */
  letter-spacing: 0.4px;

  /* Color/Main */
  color: #363B64;


  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.process .container .row .col-sm-6 .boxB {

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 45px;
  gap: 14px;

  width: 167px;
  height: 56px;

  background: #F1F6FA;
  border-radius: 14px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.process .button {

  padding: 10px 20px;
  height: 50px;
  background: #FFAF00;
  border-radius: 15px;
}

.process .button .h4 {
  /* Submit */

  width: 112px;
  height: 67px;

  /* Heading 1 */
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 67px;

  color: #000000;


  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.display-end {
  display: flex;
  justify-content: end;
}

.display-center {
  display: flex;
  justify-content: center;
}

.pro-name {
  width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000000;
  margin: auto;
  display: inline-block;
}