@font-face {
  font-family: Plaster-Regular;
  /* src: url('../fonts/Plaster-Regular.ttf'); */
}

@font-face {
  font-family: Playfair-Display;
  /* src: url('../fonts/PlayfairDisplay-Regular.otf'); */
}

.form-content .form-button {
  margin-top: 0px !important;
  margin-bottom: 10px !important;
}

.googleBtn {
  box-sizing: border-box;
  display: -webkit-flex;
  padding: 5px 18px;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  cursor: pointer;
  margin-right: auto;
  margin-left: auto;
  border-radius: 10px;
  transition: background .15s;
  color: #000;
  max-width: 350px;
  border: 1px solid #ddd;
  width: 100%;
  margin-top: 5%;
}

.googleBtn img {
  width: 20px;
  margin-right: 9px;
}

.orLogin {
  text-transform: uppercase;
  margin-top: 15px;
  color: #000;
}

.orLogin h3 {
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  text-align: center;
  color: #5e5c5c;
  padding-top: -10px;
}

.img-holder .info-holder h1 {
  font-family: Plaster-Regular;
  font-weight: 600;
  color: #fff;
  margin-left: 5%;
  text-align: left;
}

.form-body {
  /* background-color: #0093FF; */
}

.website-logo {
  display: inline-block;
  top: 50px;
  left: 50px;
  right: initial;
  bottom: initial;
}

.website-logo img {
  width: 100px;
}

.website-logo .logo {
  background-image: url("../../public/images/hammer-1.png");
}

.website-logo .logo img {
  width: 100px;
}

.website-logo-inside img {
  width: 100px;
}

.website-logo-inside .logo {
  background-image: url("../../public/images/hammer-1.png");
}

.website-logo-inside .logo img {
  width: 100px;
}

.img-holder {
  width: 550px;
  background-color: #000000;
}

.img-holder .info-holder h3 {
  color: #fff;
  text-align: left;
}

.img-holder .info-holder h3 span {
  color: #fff;
}

.img-holder .info-holder h2 {
  color: #fff;
  text-align: left;
}

.img-holder .info-holder h2 span {
  color: #fff;
}

.img-holder .info-holder p {
  color: #fff;
  text-align: left;
}

.img-holder .bg {
  opacity: 0.23;
  background-image: url("../../public/images/img1.png");
}

.form-holder {
  margin-left: 550px;
}

.form-holder .form-content ::-webkit-input-placeholder {
  color: #526489 !important;
}

.form-holder .form-content :-moz-placeholder {
  color: #526489 !important;
}

.form-holder .form-content ::-moz-placeholder {
  color: #526489 !important;
}

.form-holder .form-content :-ms-input-placeholder {
  color: #526489 !important;
}

.form-content {
  background-color: #fff;
}

.form-content .form-group {
  color: #fff;
}

.form-content .form-items {
  max-width: 380px;
  text-align: left;
}

.humer_footer {
  position: absolute;
  width: 18%;
  height: 28%;
  bottom: 0;
  right: 1%;
}

.form-content .form-icon {
  margin-top: calc(-42px - 35px);
}

.form-content .form-icon .icon-holder {
  background-color: #4A77F7;
}

.form-content h3 {
  color: #FFAF00;
  text-align: left;
  font-family: Playfair Display;
}

.form-content p {
  color: #313131;
  text-align: left;
  font-family: Playfair Display;
}

.form-content label {
  color: #000;
  text-align: left;
}

.form-content .page-links a {
  color: #000;
}

.form-content .page-links a:after {
  background-color: rgba(255, 255, 255, 0.5);
}

.form-content .page-links a.active:after {
  background-color: #fff;
}

.form-content .page-links a:hover:after,
.form-content .page-links a:focus:after {
  background-color: #fff;
}

.form-content input,
.form-content .dropdown-toggle.btn-default {
  border: 1px solid #ddd;
  background-color: #fff;
  color: #8D8D8D;
}

.form-content input:hover,
.form-content input:focus,
.form-content .dropdown-toggle.btn-default:hover,
.form-content .dropdown-toggle.btn-default:focus {
  border: 0;
  background-color: #ebeff8;
  color: #8D8D8D;
}

.form-content textarea {
  border: 0;
  background-color: #fff;
  color: #8D8D8D;
}

.form-content textarea:hover,
.form-content textarea:focus {
  border: 0;
  background-color: #ebeff8;
  color: #8D8D8D;
}

.form-content .custom-file-label {
  border: 0;
  background-color: #fff;
  color: #8D8D8D;
}

.form-content .custom-file-label:after {
  color: #0093FF;
}



.ibtn {
  background-color: #FFAF00;
  color: #fff;
  -webkit-box-shadow: 0 0 0 rgb(0 0 0 / 16%);
  box-shadow: 0 0 0 rgb(0 0 0 / 16%);
  width: 100%;
  height: 48px;
}

.ibtn:hover,
.ibtn:focus {
  background-color: #222;
  color: #fff;
  -webkit-box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16);
}

.form-content .form-button a {
  color: #000;
  text-decoration: none;
}

.form-content .other-links span {
  color: #000;
}

.form-content .other-links a {
  color: #FFAF00;
  text-decoration: none;
}

.form-content .other-links a:hover {
  color: rgb(253, 88, 28);
 
}

.form-content .form-sent .tick-holder .tick-icon {
  background-color: rgba(255, 255, 255, 0);
}

.form-content .form-sent .tick-holder .tick-icon:before {
  background-color: #fff;
}

.form-content .form-sent .tick-holder .tick-icon:after {
  background-color: #fff;
}

.form-content .form-sent h3 {
  color: #fff;
}

.form-content .form-sent p {
  color: #fff;
}

.form-content .form-sent .info-holder {
  color: #fff;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.form-content .form-sent .info-holder span {
  color: #fff;
}

.form-content .form-sent .info-holder a {
  color: #fff;
}

@keyframes tick-anime3 {
  0% {
    background-color: rgba(255, 255, 255, 0);
    -webkit-transform: rotate(35deg) scale(2);
    -moz-transform: rotate(35deg) scale(2);
    -ms-transform: rotate(35deg) scale(2);
    transform: rotate(35deg) scale(2);
  }

  100% {
    background-color: rgba(255, 255, 255, 0.2);
    -webkit-transform: rotate(45deg) scale(1);
    -moz-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
}

.alert {
  color: #fff;
}

.alert.alert-primary {
  background-color: rgba(226, 240, 255, 0.1);
  border-color: #fff;
}

.alert.alert-primary hr {
  border-top-color: #fff;
}

.alert.alert-secondary {
  background-color: rgba(240, 240, 240, 0.1);
  border-color: #fff;
}

.alert.alert-secondary hr {
  border-top-color: #fff;
}

.alert.alert-success {
  background-color: rgba(247, 255, 240, 0.1);
  border-color: #fff;
}

.alert.alert-success hr {
  border-top-color: #fff;
}

.alert.alert-danger {
  background-color: rgba(255, 250, 250, 0.1);
  border-color: #fff;
}

.alert.alert-danger hr {
  border-top-color: #fff;
}

.alert.alert-warning {
  background-color: rgba(255, 248, 225, 0.1);
  border-color: #fff;
}

.alert.alert-warning hr {
  border-top-color: #fff;
}

.alert.alert-info {
  background-color: rgba(220, 237, 241, 0.1);
  border-color: #fff;
}

.alert.alert-info hr {
  border-top-color: #fff;
}

.alert.alert-light {
  background-color: rgba(254, 254, 254, 0.1);
  border-color: #fff;
}

.alert.alert-light hr {
  border-top-color: #fff;
}

.alert.alert-dark {
  background-color: rgba(214, 216, 217, 0.1);
  border-color: #fff;
}

.alert.alert-dark hr {
  border-top-color: #fff;
}

.alert.with-icon.alert-primary:before {
  color: #fff;
}

.alert.with-icon.alert-secondary:before {
  color: #fff;
}

.alert.with-icon.alert-success:before {
  color: #fff;
}

.alert.with-icon.alert-danger:before {
  color: #fff;
}

.alert.with-icon.alert-warning:before {
  color: #fff;
}

.alert.with-icon.alert-info:before {
  color: #fff;
}

.alert.with-icon.alert-light:before {
  color: #fff;
}

.alert.with-icon.alert-dark:before {
  color: #fff;
}

.alert a,
.alert a.alert-link {
  color: #fff;
}

.alert .close {
  color: #fff;
}

.alert .close span {
  color: #fff;
}

.form-subtitle {
  color: #fff;
}

.rad-with-details .more-info {
  color: #fff;
}

.form-body.without-side h3 {
  color: #000;
}

.form-body.without-side p {
  color: #000;
}

.form-body.without-side label {
  color: #000;
}

.form-body.without-side .img-holder .info-holder img {
  display: none;
}

.form-body.without-side .form-content .form-items {
  padding: 35px 30px;
  background-color: #fff;
}

.form-body.without-side .form-content .form-items .other-links .text {
  color: #000;
}

.form-body.without-side .form-content .form-items .other-links a {
  color: #000;
  background-color: #F7F7F7;
}

.form-body.without-side .form-content .page-links a {
  color: #000;
}

.form-body.without-side .form-content .page-links a:after {
  background-color: rgba(255, 255, 255, 0.5);
}

.form-body.without-side .form-content .page-links a.active:after {
  background-color: #fff;
}

.form-body.without-side .form-content .page-links a:hover:after,
.form-body.without-side .form-content .page-links a:focus:after {
  background-color: #fff;
}

.form-body.without-side .form-content input,
.form-body.without-side .form-content .dropdown-toggle.btn-default {
  border: 0;
  background-color: #fff;
  color: #8D8D8D;
}

.form-body.without-side .form-content input:hover,
.form-body.without-side .form-content input:focus,
.form-body.without-side .form-content .dropdown-toggle.btn-default:hover,
.form-body.without-side .form-content .dropdown-toggle.btn-default:focus {
  border: 0;
  background-color: #fff;
  color: #8D8D8D;
}

.form-body.without-side .form-content .form-button .ibtn {
  background-color: #fff;
  color: #29A4FF;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.16);
}

.form-body.without-side .form-content .form-button .ibtn:hover,
.form-body.without-side .form-content .form-button .ibtn:focus {
  -webkit-box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16);
}

.form-body.without-side .form-content .form-button a {
  color: #fff;
}

/* -----------------------------------
    2 - Responsive Styles
------------------------------------*/
@media (max-width: 992px) {
  .form-holder {
    margin-left: 0;
  }

  .website-logo {
    top: 50px;
    left: 50px;
    right: initial;
    bottom: initial;
  }

  .website-logo .logo {
    background-image: url("../../public/images/hammer-1.png");
  }

  .form-body.without-side .website-logo .logo {
    background-image: url("../../public/images/hammer-1.png");
  }
}
.start1{
  color:"rgb(255, 69, 0)";  
}

