.chat_block {
  position: fixed;
  display: block;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100% !important;
  z-index: 9999;
  /* background-color: rgba(255, 255, 255, 0.7); */
}

.chat_block span {
  left: 50%;
  top: 50%;
  position: relative;
}

.chat_block_css {
  /* position: fixed;
  display: block; */
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100% !important;
  /* z-index: 9999; */
  background-color: transparent;
}