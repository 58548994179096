.file-icon {
  margin-left: 7%;
  /* margin-top: 30%; */
  font-size: 25px;
  color: #FF844B;
}

.video-icon {
  margin-left: 7%;
  /* margin-top: 30%; */
  font-size: 25px;
  color: #7D6AEB;
}

.pdf-icon {
  margin-left: 7%;
  /* margin-top: 30%; */
  font-size: 25px;
  color: #3CC2E0;
}

.progressbar {
  height: 10px;
  margin-top: 15px;
}

.fileNum {
  font-size: 12px;
  color: #6F96AA
}

.fileSize {
  font-size: 12px;
}

.bg-primary {
  background-color: #7D6AEB !important;
}

.add {
  height: 40px;
  width: auto;
  margin-left: 20%;
  border-radius: 8px;
  background-color: #ffc107;
  margin-top: 2%;
  padding: 10px;
}

.addNew-css {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  margin-left: 10px;
  /* margin-right: auto;
  margin-top: -3%; */
  /* margin-bottom: auto; */
}

.searchboxx {
  height: auto;
  width: 60%;
  background-color: white;
  border-radius: 20px;
  padding: 2%;
  display: inline-block;
  margin-left: 5%;
}

input:focus {
  border: 0px solid;
  box-shadow: none;
  border-style: none;
}

.table thead th {
  color: #4C4D4E;
  font-size: 18px;
  font-weight: 600;
}

.time {
  font-size: 14px;
  color: #6F96AA;
}

.status {
  color: #4CF610;
}

.status-process {
  color: #4CF610;
}

.status-pending {
  color: #4CF610;
}

.comment {
  color: #6418C39E;
  font-size: 20px;
}

.dotbox {
  background-color: #588AF3;
  height: 30px;
  width: 30px;
  border-radius: 10px;
}

.dot {
  font-size: 20px;
  color: white;
  margin-left: 12px;
  margin-top: 5px;
  /* margin-right: auto; */
}

.headBox {
  border-color: #FFAF00;
  border-radius: 5px;
  border-width: 2px;
  border-style: solid;
  height: 35px;
  width: 105px;
  flex-direction: row;
  padding: 3px;
  background: none;
}

.subBox {
  background-color: #C5E4FC;
  border-radius: 5px;
  /* border-width: 2px; */
  /* border-style: solid; */
  height: 35px;
  width: 100px;
  flex-direction: row;
  padding: 3px;
}

.note {
  border-left-color: #FFAF00;
  border-left-width: 4px;
  border-left-style: solid;
  margin-left: -20px;
}

.star-box {
  width: 50px;
  height: 50px;
  background-color: #FFEBCC;
  border-radius: 14px;
}

.star {
  margin-left: 10px;
  margin-top: 12px;
  color: #FFAB2D;
  font-size: 22px;
}

.button-manage {
  margin-left: 450px;
}

@media (max-width: 1000px) {
  .button1 {
    margin-left: 0px;
  }
}

.customer-list {
  margin-top: 10px;
}

.user-table tbody tr {
  margin-bottom: 20px;
}