.price-top {
  background-image: url('../components/images/price-bg.png');
  height: 500px;
  width: 100%;
  padding-top: 50px;
  display: grid;
  /* align-items: center; */
  /* justify-content: flex-start; */
  place-content: center;
  /* background: rgba(0, 0, 0, 0.61); */
}

.head-text {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;

  /* identical to box height */

  color: #FFAF00;
}

.sub-text {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  width: 60%;
  color: #FFFFFF;
}

.second-part {
  padding: 60px;
}

.head-part {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  width: 50%;
  margin: auto;
  color: #000000;
}

.price-card {
  width: 90%;
  background-color: #D9D9D9;
  height: auto;
}

.price-row {
  width: 80%;
  margin: auto;
}

.card-top {
  background-color: #353C4E;
  width: 100%;
  height: auto;
  text-align: center;
  padding: 20px;
}

.card-cal {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;

  color: #FFAF00;
}

.card-text {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;

  color: #FFFFFF;
}

.yellow-circle {
  width: 50px;
  height: 50px;
  border-radius: 40px;
  background-color: #FFAF00;
  margin-left: auto;
  margin-right: auto;
  margin-top: -15%;
  padding: 10px;
}

.yellow-circle i {
  font-size: 20px;
  color: #fff;
  margin-top: 10%;
}

.price-card-row {
  display: flex;
  justify-content: center;
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 15px;
  color: #000000;
}

.card-bottom {
  background-color: #FFAF00;
  width: 100%;
  height: auto;
  text-align: center;
  padding: 10px;
}

.price-card-row-css {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;

  color: #FFFFFF;
}

.third-part {
  width: 100%;
  background-color: #F3EFEF;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.third-text {
  font-family: 'cairo';
  font-style: normal;
  font-weight: 400;
  font-size: 27px;
  line-height: 38px;

  /* identical to box height */
  text-align: center;

  color: #000000;
}

.price-bottom {
  background-image: url('../components/images/price-bg2.png');
  height: 600px;
  width: 100%;
  padding-top: 50px;
  display: grid;
  /* align-items: center; */
  /* justify-content: flex-start; */
  place-content: center;
}

.bottom-text {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.035em;
  width: 60%;
  color: #FFFFFF;
}

.contact-box {
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  width: 15%;
  height: auto;
  padding: 10px;
  text-align: center;
}

.contact-btn-text {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.035em;

  color: #FFFFFF;
}

@media only screen and (min-device-width: 330px) and (max-device-width: 450px) {
  .second-part {
    padding: 10px;
  }

  .head-part {
    width: 100%;
  }

  .price-card {
    width: 100%;
  }

  .sub-text,
  .bottom-text {
    width: 90%;
  }

  .contact-box {
    width: 50%;
  }
}