/*  CSS For hero-slider */
.hero-area {
  /* background-image: url("../images/slider/slider-bg-1.jpg"); */
  background-size: cover;
  /* height: 100vh; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-area:before {
  content: '';
  background: rgba(0, 0, 0, 0.63);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hero-area .block {
  color: #fff;
}

.hero-area .block .col-md-12 {
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.hero-area .block p {
  color: #fff;
  width: 50%;
  margin-bottom: 20px;
}

.hero-area .block .btn-main {
  margin-right: 8px;
}

.hero-area .block .btn-main:hover {
  opacity: .8;
}

.hero-area-video {
  height: 100vh;
  position: inherit !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-area-video .block {
  color: #fff;
  text-align: center;
}

.hero-area-video .block .col-md-12 {
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 20px;
}

.hero-area-video .block p {
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 3px;
  margin-bottom: 20px;
}

.hero-area-video .block .btn-main {
  margin-top: 20px;
}

.dark-bg {
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
}

.hero-slider {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}

.hero-slider::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
}

.hero-slider .container {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.hero-slider .col-md-12 {
  color: #fff;
  font-size: 60px;
  line-height: 70px;
  letter-spacing: 2px;
  font-weight: 700;
  margin-bottom: 20px;
}

.hero-slider p {
  color: #fff;
  margin-bottom: 20px;
  font-size: 21px;
  text-align: center;
}


.hero-slider {
  overflow-x: hidden;
}

.hero-slider .prevArrow {
  left: -100px;
}

.hero-slider .nextArrow {
  right: -100px;
}

.hero-slider:hover .prevArrow {
  left: 20px;
}

.hero-slider:hover .nextArrow {
  right: 20px;
}

/* slick style */
.slick-slide {
  outline: 0;
}

.slick-slide img {
  display: unset;
}

.slick-arrow::before {
  position: absolute;
  content: '';
  height: 10px;
  width: 10px;
  background: transparent;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(-45deg);
  transform: translateY(-50%) rotate(-45deg);
  transition: .2s ease;
}

.slick-arrow::after {
  position: absolute;
  content: '';
  height: 2px;
  width: 25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #fff;
  transition: .2s ease;
}

.slick-arrow:focus {
  outline: 0;
}

.slick-arrow:hover {
  background: #28ABE3;
}

.prevArrow {
  left: 0px;
}

.prevArrow::before {
  border-left: 2px solid #fff;
  border-top: 2px solid #fff;
  right: 35px;
}

.prevArrow::after {
  right: 20px;
}

.nextArrow {
  right: 0px;
}

.nextArrow::before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  left: 35px;
}

.nextArrow::after {
  left: 20px;
}

.animationheader {
  animation-delay: 0.1s;
  animation-duration: 0.3s;
  /* "opacity: 0; animation-delay: 0.1s; animation-duration: 0.3s;" */
}

carousel css .carousel-caption {
  bottom: 200px;
}

.carousel-caption h1 {
  color: #fff;
  font-size: 60px;
  font-family: "Catamaran", sans-serif;
}

.carousel-control-prev-icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9;
  height: 70px;
  width: 70px;
  /* background: rgba(40, 171, 227, 0.5); */
  color: #fff;
  border: 0;
  line-height: 70px;
  font-size: 35px;
  transition: .2s ease;
  border-radius: 50%;
  /* background-image: url("../components/arrow-left-solid.svg"); */
}

.carousel-control-next-icon {
  background: transparent;
  transition: .2s ease;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 30px;
}

.carousel-indicators {
  z-index: "";
}

/*  hero-slider css completes  */

.slider-item {
  height: 650px !important;
}

.slide-caption {
  bottom: 150px !important;
}


@media only screen and (max-width: 600px) {
  .slider-item {
    height: auto !important;
  }

  a.call_action {
    display: none;
  }

  .hero-slider {
    margin-top: 55px;
  }

  .post-item .content {
    padding: 5px;
  }

  section#about {
    padding-top: 50px;
  }

  .footer-bottom h5 {
    line-height: normal !important;
  }
}

@media only screen and (max-width: 400px) {
  .slider-item {
    height: 200px !important;
  }

  a.call_action {
    display: none;
  }

  .hero-slider {
    margin-top: 55px;
  }

  .post-item .content {
    padding: 5px;
  }

  section#about {
    padding-top: 50px;
  }

  .footer-bottom h5 {
    line-height: normal !important;
  }
}