.trans-drop {
  display: flex;
  justify-content: end;
}

.status-trans {
  text-transform: capitalize;
}

.tr-width {
  width: 50%;
}

.tr-width .form-control {
  width: 40%;
}