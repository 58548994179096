.process .mb-3 h3 {

  width: 124px;
  height: 67px;
  left: 490px;
  top: 306px;


  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 67px;

  color: #000000;
}

.process Label h4 {
  width: 183px;
  height: 45px;

  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 45px;

  /* identical to box height */

  color: #000000;


  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.process .Control {
  box-sizing: border-box;

  width: 921px;
  height: 76px;

  background: #FBFBFB;
  border: 2px solid #979797;
  border-radius: 5px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.process .Control {
  box-sizing: border-box;

  width: 921px;
  height: 76px;

  background: #FBFBFB;
  border: 2px solid #979797;
  border-radius: 5px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.process .container .row .col-sm-6 .boxA {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 45px;
  gap: 14px;

  width: 196px;
  height: 56px;

  background: rgba(250, 212, 129, 0.5);
  border-radius: 14px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.process .container .row .col-sm-6 .form-check-input {
  /* Auto layout */
  justify-items: center;
}

.process .container .row .col-sm-6 .boxA .form-check-input {

  background: #FF844B;
}

.process .container .row .col-sm-6 label {
  width: 106px;
  height: 24px;

  /* Button / Button lg */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  /* identical to box height */
  letter-spacing: 0.4px;

  /* Color/Main */
  color: #363B64;


  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.process .container .row .col-sm-6 .boxB {

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 45px;
  gap: 14px;

  width: 167px;
  height: 56px;

  background: #F1F6FA;
  border-radius: 14px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.process .button {

  padding: 10px 20px;
  height: 50px;
  background: #FFAF00;
  border-radius: 15px;
}

.process .button .h4 {
  /* Submit */

  width: 112px;
  height: 67px;

  /* Heading 1 */
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 67px;

  color: #000000;


  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.accept {
  color: red;
  font-size: 25px;
}

.click {
  margin-left: 0px;
}

.accept {
  color: red;
  font-size: 25px;
}

h4 {

  height: 45px;
  left: 555px;
  top: 257px;

  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 45px;

  color: #000000;

}

p {

  font-size: 14px;
  text-align: justify;
  font-style: normal;
  line-height: 18px;
  color: #000000;

}

.click {
  /* background: red;  */
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;

}

.acceptA {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 13px;
  gap: 10px;

  background: #3DE04D;
  border-radius: 10px;
  justify-content: center;
}

.acceptB {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 13px;
  gap: 10px;

  background: rgba(255, 31, 31, 0.73);
  border-radius: 10px;
  justify-content: center;
}

.form-control:focus {
  box-shadow: none;
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.document-btn {
  width: 80%;
  padding: 8px 5px;
  border-radius: 10px;
  height: auto;
  text-align: center;
  background-color: #fff;
  color: #17a2b8;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  border: 1px solid #17a2b8;
  cursor: pointer;
}

.document-btn i {
  margin-right: 10px;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .process-row {
    flex-wrap: nowrap;
  }
}