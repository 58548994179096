.theamheader .containerA {
    position: relative;
    justify-content: center;
    background: #F4F6F8;

}

.theamheader .containerA .row .d-block {
    width: 679px;
    height: 444px;
    left: 1064px;
    top: 118px;
}

.theamheader .containerA .row .column {
    margin-left: 200px !important;
}

.theamheader .containerA .row .col-lg-6 .display {

    width: 818px;
    height: 90px;

    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 90px;



    color: #000000;
}

.theamheader .containerA .row .col-lg-6 span {
    color: #FFAF00;
}

.theamheader .containerA .row .col-lg-6 .lead {
    width: 500px;
    height: 134px;
    left: 374px;
    top: 340px;
    /* font-family: 'Poppins'; */
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
}

.theamheader .containerB {
    margin-top: 48px;
    /* max-width: 90%; */
}

.theamheader .containerB .row .col-sm-6 .Text {
    /* position: absolute; */
    /* width: 506px; */
    height: 48px;
    left: 424px;
    margin-top: 48px;

    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 48px;

    /* identical to box height */

    color: #000000;
}

.theamheader .containerB .row .col-sm-6 .Textparagraph {
    color: #3E3E3E;
    font-size: 15px;
}

.theamheader .containerB .row .col-sm-6 .image .container img {
    padding-right: 460px;
    margin-left: -60px;
}

@media (max-width: 992px) {
    .theamheader .containerA .row .d-block{
        max-width: 100%;
          height: auto;
      }
      .theamheader .containerA .row .column{margin-left: 0px !important;}
      .theamheader .containerA .row .col-lg-6 .display{max-width: 100%;line-height: normal;font-size: 36px !important;}
      .theamheader .containerA .row .col-lg-6 .lead{max-width: 100%;}
      .theamheader .containerB .row .col-sm-6 .Text {
        position: relative;
        left: auto;
        margin-top: 10px;
        height: auto;
        width: auto;
    }
    .theamheader .containerB .row .col-sm-6 .image .container img {
        max-width: 100%;
        padding: 5px;
        margin: 0px auto;
    }
    .theamheader .containerB{    padding: 15px;}
}