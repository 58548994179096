.blog {
    padding-bottom: 110px;
    padding-top: 70px;
    background: #1F2732;
    position: relative;
  }
  .blog::before {
      content: '\f0d7';
      position: absolute;
      color: #fff;
      left: 50%;
      top: -32px;
      font-family: "Font Awesome 5 free";
      font-weight: 900;
      font-size: 48px;
      transform: translate(-50%, 0px);
  }
  .post-item {
    margin-bottom: 2px;
        padding: 40px 15px;
        border-right: 1px solid #e1e1e1;
  }
  .border-top-1 {
      border-top: 1px solid #e1e1e1;
  }
  .practice-block {
      padding: 0;
  }
  .post-item-0 {
    border-right: 0px
  } 
  .media-wrapper {
      text-align: center;
  }
  .post-item .content {
    padding: 20px;
  }
  
  .post-item .content h3 a {
    color: #000;
  }
  
  .post-item .content p {
    color: #757575;
    font-size: 14px;
    margin: 10px 0;
  }
  
  .post-item .content .btn-main {
    padding: 12px 25px;
    margin: 15px 0;
  }
  
  /* 8.2.1 Blog Details */
  .blog-details h3 {
    font-size: 30px;
    color: #000;
    margin-bottom: 10px;
  }
  
  /* 8.2.2 Post Content */
  .post-content ul {
    margin-bottom: 20px;
  }
  
  .post-content ul li {
    color: #aaa;
  }
  
  .post-content ul li a {
    color: #aaa;
  }
  
  .post-content p {
    margin-bottom: 30px;
  }
  
  .post-content blockquote {
    border-left: 2px solid #28ABE3;
    padding: 40px 35px;
    margin-bottom: 30px;
    background: #eeeeee;
  }
  
  .post-content-share {
    margin-bottom: 100px;
  }
  
  .post-content-share li {
    height: 45px;
    width: 45px;
    border: 1px solid #ddd;
    text-align: center;
    background: #fff;
    transition: .3s ease;
    margin-right: 10px;
  }
  
  .post-content-share li a i {
    line-height: 45px;
    color: #000;
    transition: .3s ease;
  }
  
  .post-content-share li:hover {
    background: #28ABE3;
    border-color: #28ABE3;
  }
  
  .post-content-share li:hover i {
    color: #fff;
  }
  
  /* 8.2.5 Widget Style */
  .widget {
    margin-bottom: 30px;
  }
  
  .widget h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .widget-search {
    position: relative;
  }
  
  .widget-search .form-control {
    border: 1px solid #dedede;
    border-radius: 0;
    height: 50px;
  }
  
  .widget-search-btn {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50px;
    background: #28ABE3;
    border: 0;
  }
  
  .widget-search-btn i {
    color: #fff;
  }
  
  .widget-categories-list {
    padding-left: 0px;
  }
  
  .widget-categories-list li {
    border-bottom: 1px solid #ddd;
  }
  
  .widget-categories-list li:last-child {
    border: 0;
  }
  
  .widget-categories-list li a {
    display: block;
    padding: 15px 0;
    color: #000;
    font-size: 15px;
    transition: .3s ease;
  }
  
  .widget-categories-list li a i {
    font-size: 10px;
    margin-right: 10px;
  }
  
  .widget-categories-list li a:hover {
    background: #28ABE3;
    color: #fff;
    padding-left: 20px;
  }
  
  .widget-post-list {
    padding-left: 0;
  }
  
  .widget-post-list-item {
    padding: 15px 0;
    display: flex;
  }
  
  .widget-post-image {
    margin-right: 10px;
    height: 90px;
    width: 130px;
    overflow: hidden;
  }
  
  .widget-post-image img {
    height: 90px;
    width: auto;
  }
  
  .widget-post-content h5 {
    font-size: 18px;
    margin-bottom: 10px;
    transition: .3s ease;
    color: #000;
  }
  
  .widget-post-content h5:hover {
    color: #28ABE3;
  }
  
  /*=================================================================
    Footer section
  ==================================================================*/
  .top-footer {
    background-color: #272E37;
    border-top: 1px solid #404040;
    padding: 50px 0 40px;
  }
  
  .top-footer h3 {
    font-size: 16px;
    line-height: 1.5;
    color: #FAD481;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 20px;
  }
  .social_media li a {
      width: 30px;
      height: 30px;
      text-align: center;
      background:  #272E37;
      border-radius: 50px;
      color: #fff !important;
  }
  .top-footer p {
    font-size: 13px;
    line-height: 30px;
    color: #bdbdbd;
    font-weight: 300;
    letter-spacing: 1px;
    padding-right: 20px;
  }
  
  .top-footer li a {
    font-size: 15px;
    line-height: 30px;
    color: #bdbdbd;
    font-weight: 300;
    letter-spacing: 1px;
    text-transform: capitalize;
    transition: color .3s;
    font-family: "Source Sans Pro", sans-serif;
    display: block;
  }
  
  .top-footer li a:hover {
    color:#FAD481;
  }
  
  .footer-bottom {
    text-align: center;
    background-color: #1b1b1b;
    border-top: 1px solid #2c2c2c;
    padding: 15px 0;
  }
  
  .footer-bottom h5 {
    font-size: 14px;
    line-height: 1;
    color: #888888;
    font-weight: 400;
    letter-spacing: 1px;
    margin-top: 7px;
    margin-bottom: 3px;
  }
  
  .footer-bottom h6 {
    font-size: 11px;
    line-height: 15px;
    color: #888888;
    font-weight: 400;
    text-transform: capitalize;
    letter-spacing: 2px;
    margin-bottom: 0;
  }
  
  .footer-bottom a {
    color: #FFAF00;
    text-decoration:none;
  }
  
  .page-404 {
    display: flex;
    align-items: center;
    height: 100vh;
  }
  
  .page-404 h1 {
    font-size: 200px;
  }
  .post-item .content h3 {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    text-align: center;
}

@media only screen and (max-width: 600px) {
  .post-item{padding: 15px 5px;border: 0px;}
  .blog {
    padding-bottom: 30px;
  }
}