.Purchase {
    margin-top: 8%;
}

.topheading1 {
    color: #414141;
    font-size: 25px;
    font-weight: bold;
}

.topheading2 {
    color: #FFAF00;
    font-size: 25px;
}

.tablehead1 {
    font-size: 15px;
    font-weight: bold;
}

.headtable {
    font-size: 15px;
    color: #000000;
    font-weight: bold;
}

.bodytable {
    font-size: 14px;
}

.bodytable .text-dark {
    font-weight: bold;
    color: #080808;

}

.bodytable .text-dark2 {
    font-weight: bold;
    color: #080808;
    font-size: 18px;

}

.bodytable .text-warning {
    font-weight: bold;
    font-size: "25px";
}

.bodytable .textcolor {
    font-weight: bold;
    font-size: "12px";
    text-align: right;
    color: #52E120;
}

.input-css .inputbtn {
    border-radius: 8px;
    border: 1px solid #C6C9D2;
    width: 60%;
    padding: 4px;
    color: #080808;
}

.progresscard {
    border: 1.5px dashed #FFAF00;
    border-radius: 5px;
}

.row .point {
    font-weight: bolder;
    font-size: 18px;
    color: #000000;
}

.row .point1 i {
    margin-right: 12px;
    background: #f5bc41 68%;
    border-radius: 12px;
    color: #e0dbdb;
    font-size: 23px;
    margin-top: 18px;
    border: none;
}

.row .point2 i {
    margin-right: 12px;
    background: #E3E8EE;
    border-radius: 12px;
    color: #e0dbdb;
    font-size: 23px;
    margin-top: 12px;
}

.bottomside {
    padding: 18px;
}

.bottomside .Bottom-ul-list {
    padding: 18px 0px;
}

.row .Bottomlist .fas {
    color: #FFAF00;
}

.row .bottomheading {
    color: #1D8EF6;
    margin-left: 12px;
}

.row .Bottomlist span {
    font-weight: 700;
    font-size: 18px;
}

.progressbar {
    margin-bottom: 25px;
    height: 15px;
}

.star-benefits i {
    color: #FFAF00;
}

.apply-box {
    height: 30px;
    /* width: 20%; */
    /* margin-left: 20%; */
    border-radius: 5px;
    background-color: #ffc107;
    color: #fff;
    /* margin-top: 2%; */
    /* padding: 2%; */
    padding: 6px 6px 6px 10px;
}

.checkout-box {
    height: 30px;
    width: 20%;
    border-radius: 5px;
    background-color: #ffc107;
    color: #fff;
    text-align: center;
    cursor: pointer;
}

.addNew-css {
    font-size: 12px;
    font-weight: 600;
    /* margin-left: 10%; */
    /* margin-top: -2%; */

}

.check-btn-css {
    display: flex;
    justify-content: right;
}

.slider .rangeslider-horizontal .rangeslider__fill {
    height: 100%;
    background-color: #806deb;
    border-radius: 10px;
    top: 0;
}

.slider1 .rangeslider-horizontal .rangeslider__fill {
    height: 100%;
    background-color: #3cc2e0;
    border-radius: 10px;
    top: 0;
}

.slider2 .rangeslider-horizontal .rangeslider__fill {
    height: 100%;
    background-color: #ff864f;
    border-radius: 10px;
    top: 0;
}