.button-css {
  height: 40px;
  width: auto;
  border-radius: 10px;
  padding-top: 12px;
}

.text-c {
  font-size: 12px;
  color: #FFF;
  text-decoration: none;
  text-align: center;
}

.text-c1 {
  font-size: 10px;
  color: #FFF;
  text-decoration: none;
  text-align: center;
}

.file-upload {
  height: 150px !important
}

.label {
  /* display: inline-block; */
  margin-bottom: .5rem;
  height: 150px !important;
}

.kiHXWb {
  width: 300px !important;
  height: 100px !important;
  padding-left: 10px !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3%;
  background: #F0F5FF;
  min-width: 200px !important;
  max-width: 250px !important;
}

.fVfcRr .file-types {
  max-width: 120px !important;
}

.text-today {
  font-size: 15px;
  color: #797979;

}

.hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top-color: currentcolor;
  border-top-style: none;
  border-top-width: 0px;
  border-top: 1px solid rgba(191, 29, 29, 0.1);

}

.history-box {
  background-color: #FAFAFA;
  border-radius: 15px;
  height: auto;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.history-box .icon-file {
  margin-top: 40%;
  margin-left: 30%;
}

.file-size {
  font-size: 14px;
  color: #858585;
}

.google-box {

  /* width: 230px !important; */
  height: 100px !important;
  padding-left: 0px !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3%;
  background: #F0F5FF;
  min-width: 200px !important;
  max-width: 269px !important;
  border: dashed 2px #0658c2;
  border-radius: 5px;
}

.box-text {
  margin-top: 30px;
  margin-left: 70px;
  color: #85888c;
}

.icon-css {
  /* margin-top: 20px; */
  font-size: 30px;
  margin-left: 20px;
}

.progress-file {
  width: 95%;
  margin-right: auto;
  margin-left: auto;
  height: 6px !important;
  border-radius: 30px;
}

.pro-box {
  background-color: #FAFAFA;
  border-radius: 15px;
  height: auto;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5px;
  padding-bottom: 1px;
  margin-bottom: 5px;
  margin-top: 10px;
}

.file-add {
  display: flex;
  justify-content: center;
}

.file-add label {
  height: 130px;
  background-color: #f0f5ff;
  margin-top: 9px;
  min-width: 100px !important;
  width: 50%;
}

.history-no {
  background-color: #F0F5FF;
  border-radius: 15px;
  height: auto;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-file-text {
  text-align: center;

}