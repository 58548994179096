.section{
    padding: 100px 0;
  }
.about {
    padding: 100px 0;
    background: #fff;
  }
  
  .about .btn-about {
    background: #28ABE3;
  }
  
  .about h4 {
    margin-top: 30px;
  }
  
  .about .feature-list {
    margin-top: 10px;
  }
  
  .about .feature-list li {
    width: 48%;
    display: inline-block;
    color: #666;
    font-size: 13px;
    font-family: "Catamaran", sans-serif;
    margin-bottom: 5px;
    font-weight: 600;
  }
  
  .about .feature-list li i {
    color: #28ABE3;
    margin-right: 8px;
  }
 