.row .maintittle {
    margin-left: 12px;
    color: #252A32;
}

.buttons {
    margin-left: auto;
}

.button1 {
    padding: 5px;
    border: none;
    border-radius: 4px;
    color: #465373;
    margin-right: 12px;
}

.button2 {
    border: none;
    border-radius: 4px;
    margin-right: 12px;
   
}

.button2 .items-center {
    color: red;
}

.card {
    border: none;
}

.row-img img {
    border-radius: 12px !important;
}

.heading {
    margin-left: 12px;
    font-size: 15px;
    font-family: 'Poppins';
}

.heading p {
    margin-top: 5px;
    font-family: 'Poppins';
    color: #838B9E;
}

.card2 {
    background: #FFFFFF;
    border: 1px solid #C6C9D2;
    border-radius: 8px;
}

.tablebody {
    color: #838B9E;

}

.tableHead {
    color: #2f2f30 !important;
    border-top: none;
}

.tablehead i {
    padding-right: 6px;
}



.invoicebtn {
    color: #fff;
    background: #4C4D4E;
    height: 45px;
    width: 320px;
    text-decoration: none;
    justify-content: left;
}

.invoicebtn:hover {
    color: white;
}

.invoicebtn i {
    margin-left: 175px;
}

.row1 .push {
    margin: 12px;
    border: none;
    background: #EFF1F9;
    width: 138px;
    height: 40px;
    color: #465373;
    border-radius: 8px;
    margin-left: 3px;
}

.headtext {
    height: 24px;
    left: 1124px;
    margin-top: 12px;

    font-family: 'Poppins';
    font-weight: 500;
    font-size: 20px !important;
    color: #838B9E !important;
    margin-bottom: 15px;
}

.row .bottomtitle {
    margin-left: 45px;
}

.row .bottomtitle2 {
    font-weight: bold;
    margin-right: 45px;
    margin-left: auto;
}

.row .inputs {
    margin-left: 15px;
    margin-bottom: 35px;
}

.row .inputs .input1 {
    border-radius: 8px;
    color: #838B9E;
    border: 1px solid #C6C9D2;
    padding: 8px;
}

.row .inputs .input2 {
    border-radius: 8px;
    border: 1px solid #C6C9D2;
    padding: 8px;
    width: 50px;
    margin-left: 30px;

}

.row .inputs .input3 {
    width: 60px;
    border-radius: 8px;
    border: none;
    color: #838B9E;
    margin-left: 75px;
}