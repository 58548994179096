.iconBox {
  height: 45px;
  width: 45px;
  border-radius: 10px;
  border-width: 2px;
  border: 1px solid #E3EBF1;
}

.text-size {
  font-size: 14px;
}

.icon {
  margin-left: 30%;
  margin-top: 28%;
  font-size: 20px;
  color: #FF844B;
}

.size {
  color: #FF844B;
}

.box {
  width: 100%;
  height: auto;
  background-color: #FF844B;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
}

.storage-font {
  font-size: 14px;
  line-height: 17px;
}

.first-card {
  padding-left: 15px;
  padding-right: 15px;
}

.box img {
  display: block;
  margin-top: 10px;
  margin-left: auto;
  margin-right: 10%;
}

.textStore {
  margin: 5% 5%;
  font-size: 24px;
  color: white;
}

.planBox {
  height: 40px;
  width: 70%;
  margin-left: 5%;
  margin-bottom: 4%;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.25);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.storage-card {
  padding: 5px;
}

.textPlan {
  /* margin-top: 5%; */
  color: white;
  font-size: 18px;
}

a:hover {
  text-decoration: none;
}

.textFile {
  margin-left: 15%;
  /* margin-right: auto; */
}

.no-record {
  text-align: center;
  font-size: 30px;
  color: #F2994A;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;

}

.no-record-card {
  height: 100% !important;
}

.textFile {
  margin-left: 10%;
  /* margin-right: auto; */
}

.textNum {
  font-size: 38px;
}

.upIcon {
  margin-left: 8%;
  margin-top: 12%;
  font-size: 35px;
  color: #38E25D;
}

.textPrcess {
  font-size: 22px;
}

.addBox {
  height: 40px;
  width: 100%;
  background-color: #C5E4FC;
}

.addNew {
  margin-left: 10%;

}

.number {
  font-size: 38px;
  color: #38E25D;
  font-weight: 700;
}

.page {
  font-size: 16px;
  font-weight: 600;

}

.page2 {
  font-size: 12px;
  font-weight: 600;
  color: #787878;
}

.Process {
  color: #787878;
  font-size: 14;
}

.viewBtn {
  height: 30px;
  /* width: 35%; */
  background-color: #FFEBCC;
  border-radius: 20px;
  text-align: center;
  /* margin-left: 20%; */
  /* margin-top: 4%; */
}

.view {
  /* margin-left: 24%;
  margin-top: 30%; */
  font-size: 12px;
  color: #FFAB2D
}

.searchbox {
  width: 100%;
  background-color: white;
  border-radius: 20px;
  padding: 16px 13px 3px;
}

.circle {
  width: 21px;
  height: 21px;
  border-radius: 20px;
  background-color: #FFAB2D;
  margin-top: 3px;
}

.circle2 {
  width: 21px;
  height: 21px;
  border-radius: 20px;
  background-color: #2bff00;
  margin-top: 3px;
}

.alert.alert-danger {
  background-color: #c5e4fc !important;
  border-color: #c5e4fc !important;
}

.listall-btn {
  color: rgb(48, 47, 47);
  background-color: rgba(254, 184, 77, 0.65);
  ;
  height: 38px;
  width: 100px;
  border-radius: 5px;
  padding: 5px 10px;
  /* margin-left: auto !important; */
  text-decoration: none !important;
}

.pagination {
  margin-left: auto;
}

.pagination li a {
  border: none;
}

ul.pagination li.active a {
  color: #000;
  background: rgba(254, 184, 77, 0.65);
}

ul.pagination li.active a:hover {
  color: white;
  background: #FF844B;
}

.pro-name a {
  color: #14172E;
  font-weight: 500;
  text-decoration: none;

}

.percent {
  position: relative;
  top: 46%;
  left: 42%;
  font-size: 23px;
}

.chart-css canvas {
  /* position: absolute; */
  /* margin-bottom: 20%; */
}

.chart-css {
  /* height: 170px; */
}

@media (max-width: 550px) {
  .percent {
    position: relative;
    top: 70%;
    left: 42%;
    font-size: 31px;
  }

  .chart-css canvas {
    position: absolute;
    margin-bottom: 20%;
  }

  .chart-css {
    height: 170px;
  }

  .col-css {
    height: 350px;
  }

  .pro-css {
    margin-top: 150px !important;
  }
}