@import url("https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900");
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800&display=swap');

/* -----------------------------------
    1 - General Styles
------------------------------------*/
*,
body {
  /* font-family: "Lato", sans-serif; */
  font-family: 'Cairo', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
}

.form-body {
  /* background-color: #0093FF; */
  height: 100%;
}

.form-body>.row {
  position: relative;
  margin-left: 0;
  margin-right: 0;
  height: 100%;
}

.website-logo {
  display: inline-block;
  position: absolute;
  z-index: 1000;
  top: 50px;
  left: 50px;
  right: initial;
  bottom: initial;
}

.website-logo img {
  width: 100px;
}

.website-logo a {
  display: inline-block;
}

.website-logo .logo {
  display: inline-block;
  background-image: url("../../public/images/hammer-1.png");
  /* background-size: contain; */
  background-repeat: no-repeat;
}

.website-logo .logo img {
  width: 100px;
}

.website-logo .logo img.logo-size {
  opacity: 0 !important;
}

.website-logo-inside {
  margin-bottom: 70px;
}

.website-logo-inside img {
  width: 100px;
}

.website-logo-inside a {
  display: inline-block;
}

.website-logo-inside .logo {
  display: inline-block;
  background-image: url("../../public/images/hammer-1.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.website-logo-inside .logo img {
  width: 100px;
}

.website-logo-inside .logo img.logo-size {
  opacity: 0 !important;
}

.preview-body {
  padding-top: 70px;
  padding-bottom: 70px;
  text-align: center;
}

.preview-body .web-logo {
  margin-bottom: 50px;
}

.preview-body .web-logo img {
  width: 130px;
}

.preview-body .web-title {
  font-size: 30px;
  font-weight: 300;
  color: #000;
  line-height: 35px;
  margin-bottom: 50px;
}

.preview-body .img-link {
  display: inline-block;
  width: 100%;
  margin: 20px 0;
  padding: 0 5px;
}

.preview-body .img-link img {
  width: 100%;
  border-radius: 15px;
  -webkit-box-shadow: 0 0 5px rgba(160, 163, 165, 0.38);
  box-shadow: 0 0 5px rgba(160, 163, 165, 0.38);
  -webkit-transition: all 0.5s cubic-bezier(0.34, 1.61, 0.7, 1);
  transition: all 0.5s cubic-bezier(0.34, 1.61, 0.7, 1);
}

.preview-body .img-link:hover img,
.preview-body .img-link:focus img {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-box-shadow: 0 11px 19px rgba(160, 163, 165, 0.3);
  box-shadow: 0 11px 19px rgba(160, 163, 165, 0.3);
}

.img-holder {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 550px;
  min-height: 700px;
  height: 100%;
  overflow: hidden;
  background-color: #000000;
  padding: 60px;
  text-align: center;
  z-index: 999;
}

.img-holder .info-holder {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

.img-holder .info-holder h3 {
  display: inline-block;
  color: #fff;
  text-align: left;
  font-size: 23px;
  font-weight: 900;
  margin-bottom: 30px;
  width: 100%;
  max-width: 378px;
  padding-right: 30px;
}

.img-holder .info-holder h2 {
  display: inline-block;
  color: #fff;
  text-align: left;
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 30px;
  width: 100%;
  max-width: 378px;
  line-height: 1.5;
}

.img-holder .info-holder h2 span {
  font-size: 32px;
  font-weight: 900;
  color: #FE4777;
}

.img-holder .info-holder p {
  display: inline-block;
  color: #fff;
  text-align: left;
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  margin-bottom: 50px;
  width: 100%;
  max-width: 378px;
  padding-right: 30px;
}

.img-holder .info-holder img {
  width: 100%;
  max-width: 378px;
}

.img-holder .info-holder img.md-size {
  max-width: 290px;
}

.img-holder .info-holder.simple-info h3 {
  padding-right: 0;
}

.img-holder .info-holder.simple-info p {
  padding-right: 0;
}

.img-holder .info-holder.simple-info img {
  max-width: 160px;
  margin-bottom: 50px;
}

.img-holder .bg {
  position: absolute;
  opacity: 0.23;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../public/images/img1.png");
  background-size: cover;
  background-position: center;
  z-index: -1;
}

@media (min-height: 700px) {
  .img-holder {
    position: fixed;
  }

  .website-logo {
    position: fixed;
  }
}

.form-holder {
  margin-left: 550px;
  width: 100%;
}

.form-holder .form-content {
  position: relative;
  text-align: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 60px;
  min-height: 100%;

}

.form-holder .form-content ::-webkit-input-placeholder {
  color: #526489;
}

.form-holder .form-content :-moz-placeholder {
  color: #526489;
}

.form-holder .form-content ::-moz-placeholder {
  color: #526489;
}

.form-holder .form-content :-ms-input-placeholder {
  color: #526489;
}

.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-control ::-webkit-input-placeholder {
  color: #526489;
}

.form-control :-moz-placeholder {
  color: #526489;
}

.form-control ::-moz-placeholder {
  color: #526489;
}

.form-control :-ms-input-placeholder {
  color: #526489;
}

.form-content {
  position: relative;
  background-color: #0093FF;
}

.form-content .form-group {
  color: #fff;
  font-size: 15px;
  font-weight: 300;
}

.form-content .form-items {
  display: inline-block;
  width: 100%;
  max-width: 380px;
  text-align: left;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.form-content .form-icon {
  text-align: center;
  width: 100%;
  line-height: 0;
  margin-top: calc(-42px - 35px);
  margin-bottom: 28px;
}

.form-content .form-icon .icon-holder {
  position: relative;
  display: inline-block;
  width: 85px;
  height: 85px;
  border-radius: 85px;
  background-color: #4A77F7;
  padding: 20px;
}

.form-content .form-icon .icon-holder img {
  position: absolute;
  width: 50%;
  top: 50%;
  left: 50%;
  margin-top: -23%;
  margin-left: -25%;
}

.form-content h3 {
  color: #fff;
  text-align: left;
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 10px;
}

.form-content h3.form-title {
  margin-bottom: 30px;
}

.form-content h3.form-title-center {
  margin-bottom: 30px;
  text-align: center;
  font-size: 22px;
}

.form-content p {
  color: #fff;
  text-align: left;
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  margin-bottom: 30px;
}

.form-content p.form-subtitle {
  font-size: 16px;
  margin-bottom: 15px;
}

.form-content small.error-message {
  color: lightcoral;
}

.form-content label {
  color: #fff;
  text-align: left;
  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
  margin-bottom: 10px;
}

.form-content .page-links {
  margin-bottom: 34px;
}

.form-content .page-links a {
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: #fff;
  font-weight: 300;
  font-size: 15px;
  margin-right: 20px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.form-content .page-links a:last-child {
  margin-right: 0;
}

.form-content .page-links a:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  left: 0;
  bottom: -10px;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.form-content .page-links a.active {
  font-weight: 700;
}

.form-content .page-links a.active:after {
  background-color: #fff;
}

.form-content .page-links a:hover:after,
.form-content .page-links a:focus:after {
  background-color: #fff;
}

.form-content input,
.form-content .dropdown-toggle.btn-default {
  width: 100%;
  padding: 9px 20px;
  min-height: 49px;
  text-align: left;
  border: 0;
  outline: 0;
  border-radius: 6px;
  background-color: #fff;
  font-size: 15px;
  font-weight: 300;
  color: #8D8D8D;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-bottom: 14px;
}

.form-content input:hover,
.form-content input:focus,
.form-content .dropdown-toggle.btn-default:hover,
.form-content .dropdown-toggle.btn-default:focus {
  border: 0;
  background-color: #ebeff8;
  color: #8D8D8D;
}

.form-content textarea {
  position: static !important;
  width: 100%;
  padding: 8px 20px;
  border-radius: 6px;
  text-align: left;
  background-color: #fff;
  border: 0;
  font-size: 15px;
  font-weight: 300;
  color: #8D8D8D;
  outline: none;
  resize: none;
  height: 120px;
  -webkit-transition: none;
  transition: none;
  margin-bottom: 14px;
}

.form-content textarea:hover,
.form-content textarea:focus {
  border: 0;
  background-color: #ebeff8;
  color: #8D8D8D;
}

.form-content .custom-file {
  margin-bottom: 14px;
}

.form-content .form-button {
  margin-top: 30px;
  margin-bottom: 25px;
}

.ibtn {
  border-radius: 6px;
  border: 0;
  padding: 6px 28px;
  background-color: #fff;
  color: #29A4FF;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  margin-right: 10px;
  outline: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.16);
}

.ibtn.ibtn-full {
  width: 100%;
}

.ibtn:last-child {
  margin-right: 0;
}

.ibtn:hover,
.ibtn:focus {
  -webkit-box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16);
}

.ibtn.less-padding {
  padding: 6px 15px !important;
}

.ibtn.extra-padding {
  font-size: 16px;
  padding: 10px 32px;
}

.form-content .form-button a {
  font-size: 13px;
  font-weight: 700;
  color: #fff;
}

.form-content .form-button.full-width {
  margin-top: 15px;
}

.form-content .form-button.full-width .ibtn {
  width: 100%;
}

.form-content .btn {
  border-radius: 6px;
  padding: 6px 28px;
  font-size: 14px;
  font-weight: 700;
  margin-right: 10px;
  border: 0;
}

.form-content .btn.btn-light {
  color: #B0C2D0;
}

.form-content .btn.btn-light:hover,
.form-content .btn.btn-light:focus {
  color: #a0b6c6;
}

.form-content .btn :last-child {
  margin-right: 0;
}

.form-content form {
  margin-bottom: 30px;
  z-index: 99;
  position: relative;
}

.form-content .other-links span {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.form-content .other-links a {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  margin-right: 10px;
}

.form-content .other-links a:last-child {
  margin-right: 0;
}

.form-content .other-links a i {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-color: #000;
  color: #fff;
  border-radius: 25px;
  text-align: center;
  padding-top: 5px;
  font-size: 15px;
  margin: 0 5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.form-content .other-links a i[class*=fa-twitter] {
  background-color: #00aced;
}

.form-content .other-links a i[class*=fa-facebook] {
  background-color: #3b5998;
}

.form-content .other-links a i[class*=fa-youtube] {
  background-color: #bb0000;
}

.form-content .other-links a i[class*=fa-google] {
  background-color: #dd4b39;
}

.form-content .other-links a i[class*=fa-linkedin] {
  background-color: #007bb6;
}

.form-content .other-links a i[class*=fa-instagram] {
  background-color: #517fa4;
}

.form-content .other-links a i:hover,
.form-content .other-links a i:focus {
  opacity: 0.8;
}

.form-content.form-sm input,
.form-content.form-sm .dropdown-toggle.btn-default {
  padding: 6px 16px;
  margin-bottom: 10px;
  font-size: 14px;
}

.form-content.form-sm textarea {
  padding: 6px 16px;
  margin-bottom: 10px;
  font-size: 14px;
}

.form-content.form-sm .form-button .ibtn {
  padding: 4px 28px;
}

.form-content.form-sm .btn {
  padding: 4px 28px;
}

.form-content .form-sent {
  position: absolute;
  text-align: center;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: scale(0.7) translateX(200px);
  -moz-transform: scale(0.7) translateX(200px);
  -ms-transform: scale(0.7) translateX(200px);
  transform: scale(0.7) translateX(200px);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.form-content .form-sent.show-it {
  opacity: 1;
  pointer-events: all;
  z-index: 2;
  -webkit-transform: scale(1) translateX(0);
  -moz-transform: scale(1) translateX(0);
  -ms-transform: scale(1) translateX(0);
  transform: scale(1) translateX(0);
}

.form-content .form-sent.show-it .tick-holder .tick-icon {
  -webkit-animation: tick-anime3 0.7s cubic-bezier(0.34, 1.61, 0.7, 1) 0s forwards;
  -moz-animation: tick-anime3 0.7s cubic-bezier(0.34, 1.61, 0.7, 1) 0s forwards;
  -ms-animation: tick-anime3 0.7s cubic-bezier(0.34, 1.61, 0.7, 1) 0s forwards;
  animation: tick-anime3 0.7s cubic-bezier(0.34, 1.61, 0.7, 1) 0s forwards;
}

.form-content .form-sent.show-it .tick-holder .tick-icon:before {
  -webkit-animation: tick-anime1 0.2s linear 0.2s forwards;
  -moz-animation: tick-anime1 0.2s linear 0.2s forwards;
  -ms-animation: tick-anime1 0.2s linear 0.2s forwards;
  animation: tick-anime1 0.2s linear 0.2s forwards;
}

.form-content .form-sent.show-it .tick-holder .tick-icon:after {
  -webkit-animation: tick-anime2 0.4s ease 0.4s forwards;
  -moz-animation: tick-anime2 0.4s ease 0.4s forwards;
  -ms-animation: tick-anime2 0.4s ease 0.4s forwards;
  animation: tick-anime2 0.4s ease 0.4s forwards;
}

.form-content .form-sent .tick-holder {
  text-align: center;
  margin-bottom: 12px;
}

.form-content .form-sent .tick-holder .tick-icon {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 0);
  -webkit-transform: rotate(35deg) scale(2);
  -moz-transform: rotate(35deg) scale(2);
  -ms-transform: rotate(35deg) scale(2);
  transform: rotate(35deg) scale(2);
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
}

.form-content .form-sent .tick-holder .tick-icon:before {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 10px;
  height: 2px;
  top: 28px;
  left: 14px;
  border-radius: 2px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
}

.form-content .form-sent .tick-holder .tick-icon:after {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 2px;
  height: 20px;
  top: 9px;
  left: 22px;
  border-radius: 2px;
  -webkit-transform-origin: center bottom;
  -moz-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
}

.form-content .form-sent h3 {
  text-align: center;
  color: #fff;
}

.form-content .form-sent p {
  text-align: center;
  color: #fff;
  font-size: 15px;
  opacity: 0.8;
  margin-bottom: 20px;
}

.form-content .form-sent .info-holder {
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding: 10px;
  margin-top: 60px;
}

.form-content .form-sent .info-holder span {
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  opacity: 0.6;
}

.form-content .form-sent .info-holder a {
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  opacity: 0.9;
}

.form-content .hide-it {
  opacity: 0;
  z-index: 1;
  pointer-events: none;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: scale(0.7) translateX(-200px);
  -moz-transform: scale(0.7) translateX(-200px);
  -ms-transform: scale(0.7) translateX(-200px);
  transform: scale(0.7) translateX(-200px);
}

.form-content .row {
  margin-right: -6px;
  margin-left: -6px;
}

.form-content .row.top-padding {
  padding-top: 30px;
}

.form-content .row.top-padding .form-button {
  margin-top: 0;
}

.form-content .row .col {
  padding-right: 6px;
  padding-left: 6px;
}

.input-with-ccicon {
  position: relative;
  display: inline-block;
  width: 100%;
}

.input-with-ccicon #ccicon {
  position: absolute;
  right: 0.6rem;
  top: 0.55rem;
  font-size: 1.6rem;
}

.input-with-ccicon #ccicon[class*=visa] {
  color: #3744a2;
}

.input-with-ccicon #ccicon[class*=amex] {
  color: #1d8bd4;
}

.input-with-ccicon #ccicon[class*=diners-club] {
  color: #1d72d4;
}

.input-with-ccicon #ccicon[class*=mastercard] {
  color: #e42613;
}

.input-with-ccicon #ccicon[class*=discover] {
  color: #ef940b;
}

.input-with-ccicon input {
  padding-right: 45px;
}

.nav-tabs {
  border-bottom: 0;
  margin-bottom: 2.2rem;
}

.nav-tabs .nav-item .nav-link {
  position: relative;
  border: 0;
  font-weight: 300;
  padding: 0.5rem 0;
  margin-right: 1.2rem;
  text-align: center;
  color: #000;
  background-color: transparent;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.nav-tabs .nav-item .nav-link:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 2px;
  background-color: #DEDEDE;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.nav-tabs .nav-item .nav-link.active {
  font-weight: 700;
}

.nav-tabs .nav-item .nav-link.active:before {
  height: 3px;
  background-color: #57D38C;
}

.form-subtitle {
  font-size: 19px;
  font-weight: 300;
  color: #fff;
  margin-bottom: 1rem;
}

.inline-el-holder .inline-el {
  display: inline-block;
  margin-right: 1.3rem;
}

.rad-with-details {
  margin-bottom: 1rem;
}

.rad-with-details .more-info {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  margin-top: 0.3rem;
}

.separator {
  border-top: 1px solid #C7C7C7;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

input.sm-content {
  max-width: 110px;
}

.form-body.on-top .website-logo {
  position: absolute;
}

.form-body.on-top .img-holder {
  display: block;
  position: relative;
  width: 100%;
  min-height: initial;
  height: initial;
  overflow: initial;
  padding: 40px;
}

.form-body.on-top .img-holder .info-holder.simple-info h3 {
  margin-bottom: 16px;
}

.form-body.on-top .img-holder .info-holder.simple-info p {
  margin-bottom: 10px;
}

.form-body.on-top .img-holder .info-holder.simple-info img {
  margin-bottom: 20px;
}

.form-body.on-top .form-holder {
  margin-left: 0;
}

@keyframes tick-anime1 {
  0% {
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
  }

  100% {
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes tick-anime2 {
  0% {
    -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
  }

  100% {
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@keyframes tick-anime3 {
  0% {
    background-color: rgba(255, 255, 255, 0);
    -webkit-transform: rotate(35deg) scale(2);
    -moz-transform: rotate(35deg) scale(2);
    -ms-transform: rotate(35deg) scale(2);
    transform: rotate(35deg) scale(2);
  }

  100% {
    background-color: rgba(255, 255, 255, 0.2);
    -webkit-transform: rotate(45deg) scale(1);
    -moz-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
}

@keyframes c-tick-anime3 {
  0% {
    background-color: rgba(233, 253, 214, 0);
    -webkit-transform: rotate(35deg) scale(2);
    -moz-transform: rotate(35deg) scale(2);
    -ms-transform: rotate(35deg) scale(2);
    transform: rotate(35deg) scale(2);
  }

  100% {
    background-color: #E9FDD6;
    -webkit-transform: rotate(45deg) scale(1);
    -moz-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
}

.alert {
  position: relative;
  padding: 6px 12px;
  border: 1px solid #000;
  color: #000000;
  font-size: 13px;
  font-weight: 700;
}

.alert a,
.alert a.alert-link {
  font-weight: 700;
  color: #000000;
}

.alert p {
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 18px;
}

.alert.alert-primary {
  background-color: #e2f0ff;
  border-color: #3a86d6;
}

.alert.alert-primary hr {
  border-top-color: #3a86d6;
}

.alert.alert-secondary {
  background-color: #f0f0f0;
  border-color: #8e9396;
}

.alert.alert-secondary hr {
  border-top-color: #8e9396;
}

.alert.alert-success {
  background-color: #F7FFF0;
  border-color: #8CCB57;
}

.alert.alert-success hr {
  border-top-color: #8CCB57;
}

.alert.alert-danger {
  background-color: #FFFAFA;
  border-color: #F55050;
}

.alert.alert-danger hr {
  border-top-color: #F55050;
}

.alert.alert-warning {
  background-color: #fff8e1;
  border-color: #f1cb4b;
}

.alert.alert-warning hr {
  border-top-color: #f1cb4b;
}

.alert.alert-info {
  background-color: #dcedf1;
  border-color: #42bfdb;
}

.alert.alert-info hr {
  border-top-color: #42bfdb;
}

.alert.alert-light {
  background-color: #fefefe;
  border-color: #a7a4a4;
}

.alert.alert-light hr {
  border-top-color: #a7a4a4;
}

.alert.alert-dark {
  background-color: #d6d8d9;
  border-color: #525557;
}

.alert.alert-dark hr {
  border-top-color: #525557;
}

.alert.with-icon {
  padding-left: 32px;
}

.alert.with-icon[class*=alert-]:before {
  position: absolute;
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 600;
  top: 7px;
  left: 7px;
  width: 20px;
  font-size: 12px;
  text-align: center;
}

.alert.with-icon.alert-primary:before {
  content: "\f12a";
  color: #3a86d6;
}

.alert.with-icon.alert-secondary:before {
  content: "\f12a";
  color: #8e9396;
}

.alert.with-icon.alert-success:before {
  content: "\f00c";
  color: #8CCB57;
}

.alert.with-icon.alert-danger:before {
  content: "\f071";
  color: #F55050;
}

.alert.with-icon.alert-warning:before {
  content: "\f06a";
  color: #f1cb4b;
}

.alert.with-icon.alert-info:before {
  content: "\f129";
  color: #42bfdb;
}

.alert.with-icon.alert-light:before {
  content: "\f12a";
  color: #a7a4a4;
}

.alert.with-icon.alert-dark:before {
  content: "\f12a";
  color: #525557;
}

.alert .close {
  color: #727272;
  font-size: 0.9rem;
  padding: 3px;
  outline: none;
}

.alert .close span {
  color: #727272;
}

.form-body.without-side .website-logo {
  top: 70px;
  left: 50%;
  margin-left: -50px;
  right: initial;
  bottom: initial;
  display: inline-block;
}

.form-body.without-side .website-logo-inside .logo {
  /* background-image: url("../images/logo-dark.svg"); */
}

.form-body.without-side .form-holder .form-content ::-webkit-input-placeholder {
  color: #000;
}

.form-body.without-side .form-holder .form-content :-moz-placeholder {
  color: #000;
}

.form-body.without-side .form-holder .form-content ::-moz-placeholder {
  color: #000;
}

.form-body.without-side .form-holder .form-content :-ms-input-placeholder {
  color: #000;
}

.form-body.without-side h3 {
  color: #000;
}

.form-body.without-side p {
  color: #000;
}

.form-body.without-side label {
  color: #000;
}

.form-body.without-side .img-holder {
  z-index: 0;
  width: 100%;
  overflow: hidden;
}

.form-body.without-side .img-holder .info-holder img {
  display: none;
  max-width: 534px;
  -webkit-animation: zoom-in-img 50s linear 0s infinite;
  -moz-animation: zoom-in-img 50s linear 0s infinite;
  -ms-animation: zoom-in-img 50s linear 0s infinite;
  animation: zoom-in-img 50s linear 0s infinite;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.form-body.without-side .form-holder {
  margin-left: 0;
}

.form-body.without-side .form-holder .form-content {
  background-color: transparent;
}

.form-body.without-side .form-content {
  padding: 125px 60px 60px;
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  -ms-perspective: 800px;
  perspective: 800px;
}

.form-body.without-side .form-content .form-items {
  padding: 35px 30px;
  border-radius: 10px;
  background-color: #fff;
  -webkit-box-shadow: 0 6px 15px rgba(0, 0, 0, 0.16);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.16);
}

.form-body.without-side .form-content .form-items form {
  margin-bottom: 0;
}

.form-body.without-side .form-content .form-items .other-links {
  margin-top: 38px;
  margin-bottom: 30px;
}

.form-body.without-side .form-content .form-items .other-links .text {
  font-size: 13px;
  font-weight: 300;
  color: #000;
  margin-bottom: 15px;
}

.form-body.without-side .form-content .form-items .other-links a {
  display: inline-block;
  padding: 5px;
  border-radius: 2px;
  color: #000;
  background-color: #F7F7F7;
}

.form-body.without-side .form-content .form-items .other-links a i {
  width: 18px;
  height: 18px;
  font-size: 9px;
  margin-left: 0;
}

.form-body.without-side .form-content .form-items .page-links {
  margin-bottom: 0;
}

.form-body.without-side .form-content .form-items .page-links a {
  font-weight: 700;
}

.form-body.without-side .form-content .form-items .page-links a:after {
  bottom: -3px;
}

.form-body.without-side .form-content .page-links a {
  color: #000;
}

.form-body.without-side .form-content .page-links a:after {
  background-color: rgba(222, 222, 222, 0.7);
}

.form-body.without-side .form-content .page-links a.active:after,
.form-body.without-side .form-content .page-links a:hover:after,
.form-body.without-side .form-content .page-links a:focus:after {
  background-color: #0092FE;
}

.form-body.without-side .form-content input,
.form-body.without-side .form-content .dropdown-toggle.btn-default {
  border: 0;
  background-color: #F7F7F7;
  color: #000000;
}

.form-body.without-side .form-content input:hover,
.form-body.without-side .form-content input:focus,
.form-body.without-side .form-content .dropdown-toggle.btn-default:hover,
.form-body.without-side .form-content .dropdown-toggle.btn-default:focus {
  border: 0;
  background-color: #eaeaea;
  color: #000000;
}

.form-body.without-side .form-content textarea {
  background-color: #F7F7F7;
  border: 0;
  color: #000000;
}

.form-body.without-side .form-content textarea:hover,
.form-body.without-side .form-content textarea:focus {
  border: 0;
  background-color: #eaeaea;
  color: #000000;
}

.form-body.without-side .form-content input[type=checkbox]:not(:checked)+label,
.form-body.without-side .form-content input[type=checkbox]:checked+label,
.form-body.without-side .form-content input[type=radio]:not(:checked)+label,
.form-body.without-side .form-content input[type=radio]:checked+label {
  color: #000;
}

.form-body.without-side .form-content input[type=checkbox]:checked+label,
.form-body.without-side .form-content input[type=radio]:checked+label {
  color: #000;
}

.form-body.without-side .form-content input[type=checkbox]:checked+label:before,
.form-body.without-side .form-content input[type=radio]:checked+label:before {
  background: #000;
  border: 0px solid #000;
}

.form-body.without-side .form-content input[type=checkbox]:not(:checked)+label:before,
.form-body.without-side .form-content input[type=radio]:not(:checked)+label:before {
  background: transparent;
  border: 2px solid #000;
}

.form-body.without-side .form-content input[type=checkbox]:not(:checked)+label:after,
.form-body.without-side .form-content input[type=checkbox]:checked+label:after {
  color: #fff;
}

.form-body.without-side .form-content input[type=radio]:not(:checked)+label:after,
.form-body.without-side .form-content input[type=radio]:checked+label:after {
  background-color: #fff;
}

.form-body.without-side .form-content .custom-options input[type=checkbox]:not(:checked)+label,
.form-body.without-side .form-content .custom-options input[type=checkbox]:checked+label,
.form-body.without-side .form-content .custom-options input[type=radio]:not(:checked)+label,
.form-body.without-side .form-content .custom-options input[type=radio]:checked+label {
  color: #606060;
  background-color: #F7F7F7;
}

.form-body.without-side .form-content .custom-options input[type=checkbox]:checked+label,
.form-body.without-side .form-content .custom-options input[type=radio]:checked+label {
  color: #fff;
  background-color: #57D38C;
  -webkit-box-shadow: 0 3px 8px rgba(74, 230, 142, 0.35);
  box-shadow: 0 3px 8px rgba(74, 230, 142, 0.35);
}

.form-body.without-side .form-content .form-button {
  margin-bottom: 0;
}

.form-body.without-side .form-content .form-button .ibtn {
  background-color: #29A4FF;
  color: #fff;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.16);
}

.form-body.without-side .form-content .form-button .ibtn:hover,
.form-body.without-side .form-content .form-button .ibtn:focus {
  -webkit-box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16);
}

.form-body.without-side .form-content .form-button a {
  font-weight: 300;
  color: #000;
}

.form-body.without-side .form-content .form-sent {
  padding: 35px 30px;
  border-radius: 10px;
  background-color: #fff;
  -webkit-box-shadow: 0 6px 15px rgba(0, 0, 0, 0.16);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.16);
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.form-body.without-side .form-content .form-sent.show-it {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.form-body.without-side .form-content .form-sent .tick-holder .tick-icon {
  -webkit-animation: c-tick-anime3 0.7s cubic-bezier(0.34, 1.61, 0.7, 1) 0s forwards;
  -moz-animation: c-tick-anime3 0.7s cubic-bezier(0.34, 1.61, 0.7, 1) 0s forwards;
  -ms-animation: c-tick-anime3 0.7s cubic-bezier(0.34, 1.61, 0.7, 1) 0s forwards;
  animation: c-tick-anime3 0.7s cubic-bezier(0.34, 1.61, 0.7, 1) 0s forwards;
  background-color: rgba(233, 253, 214, 0);
}

.form-body.without-side .form-content .form-sent .tick-holder .tick-icon:before {
  background-color: #8CCB57;
}

.form-body.without-side .form-content .form-sent .tick-holder .tick-icon:after {
  background-color: #8CCB57;
}

.form-body.without-side .form-content .form-sent h3 {
  color: #000;
}

.form-body.without-side .form-content .form-sent p {
  color: #000;
}

.form-body.without-side .form-content .form-sent .info-holder {
  border-top: 1px solid rgba(0, 0, 0, 0.5);
}

.form-body.without-side .form-content .form-sent .info-holder span {
  color: #000;
}

.form-body.without-side .form-content .form-sent .info-holder a {
  color: #000;
}

.form-body.without-side .form-content .hide-it {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

@keyframes zoom-in-img {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    -ms-transform: scale(1.15);
    transform: scale(1.15);
  }

  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.gmail_login {
  /* max-width: 150px; */
  margin: 15px auto;
}

.other-links.text-center {
  margin: 18px auto;
}

/* -----------------------------------
    2 - Responsive Styles
------------------------------------*/
@media (max-width: 992px) {
  .img-holder {
    display: none;
  }

  .form-holder {
    margin-left: 0;
  }

  .form-holder .form-content {
    padding: 30px 20px 20px;
  }



  .form-body.on-top .form-holder .form-content,
  .form-body.on-top-mobile .form-holder .form-content {
    padding: 60px;
  }

  .website-logo {
    position: relative;
    top: 50px;
    left: 50px;
    right: initial;
    bottom: initial;
  }

  .website-logo .logo {
    /* background-image: url("../images/logo-light.svg"); */
  }

  .form-body.without-side .img-holder {
    display: inline-block;
  }

  .form-body.without-side .website-logo .logo {
    /* background-image: url("../images/logo-light.svg"); */
  }

  .form-body.without-side .form-holder .form-content {
    padding: 125px 30px 60px;
  }

  .form-body.on-top-mobile .website-logo {
    position: absolute;
  }

  .form-body.on-top-mobile .img-holder {
    display: block;
    position: relative;
    width: 100%;
    min-height: initial;
    height: initial;
    overflow: initial;
    padding: 40px;
  }

  .form-body.on-top-mobile .img-holder .info-holder.simple-info h3 {
    margin-bottom: 16px;
  }

  .form-body.on-top-mobile .img-holder .info-holder.simple-info p {
    margin-bottom: 10px;
  }

  .form-body.on-top-mobile .img-holder .info-holder.simple-info img {
    margin-bottom: 20px;
  }

  .form-body.on-top-mobile .form-holder {
    margin-left: 0;
  }
}

@media (max-width: 575px) {

  .form-body.on-top .img-holder,
  .form-body.on-top-mobile .img-holder {
    padding: 90px 40px 40px;
  }

  .form-content .row.top-padding .form-button {
    text-align: left !important;
    margin-top: 30px;
  }


}

@font-face {
  font-family: Plaster-Regular !important;
  /* src: url('../fonts/Plaster-Regular.ttf'); */
}

@font-face {
  font-family: Playfair-Display;
  /* src: url('../fonts/PlayfairDisplay-Regular.otf'); */
}

.img-holder .info-holder h1 {
  font-family: Plaster-Regular;
  font-weight: 600;
  color: #fff;
  margin-left: 5%;
  text-align: left;
}

.form-body {
  /* background-color: #0093FF; */
}

.website-logo {
  display: inline-block;
  top: 50px;
  left: 50px;
  right: initial;
  bottom: initial;
}

.website-logo img {
  width: 100px;
}

.website-logo .logo {
  background-image: url("../../public/images/hammer-1.png");
}

.website-logo .logo img {
  width: 100px;
}

.website-logo-inside img {
  width: 100px;
}

.website-logo-inside .logo {
  background-image: url("../../public/images/hammer-1.png");
}

.website-logo-inside .logo img {
  width: 100px;
}

.img-holder {
  width: 550px;
  background-color: #000000;
}

.img-holder .info-holder h3 {
  color: #fff;
  text-align: left;
}

.img-holder .info-holder h3 span {
  color: #fff;
}

.img-holder .info-holder h2 {
  color: #fff;
  text-align: left;
}

.img-holder .info-holder h2 span {
  color: #fff;
}

.img-holder .info-holder p {
  color: #fff;
  text-align: left;
}

.img-holder .bg {
  opacity: 0.23;
  background-image: url("../../public/images/img1.png");
}

.form-holder {
  margin-left: 550px;
}

.form-holder .form-content ::-webkit-input-placeholder {
  color: #526489 !important;
}

.form-holder .form-content :-moz-placeholder {
  color: #526489 !important;
}

.form-holder .form-content ::-moz-placeholder {
  color: #526489 !important;
}

.form-holder .form-content :-ms-input-placeholder {
  color: #526489 !important;
}

.form-content {
  background-color: #fff;
}

.form-content .form-group {
  color: #fff;
}

.form-content .form-items {
  max-width: 380px;
  text-align: left;
}

.humer_footer {
  position: absolute;
  width: 18%;
  height: 28%;
  bottom: 0;
  right: 1%;
}

.form-content .form-icon {
  margin-top: calc(-42px - 35px);
}

.form-content .form-icon .icon-holder {
  background-color: #4A77F7;
}

.form-content h3 {
  color: #FFAF00;
  text-align: left;
  font-family: Playfair Display;
}

.form-content p {
  color: #313131;
  text-align: left;
  font-family: Playfair Display;
}

.form-content label {
  color: #000;
  text-align: left;
}

.form-content .page-links a {
  color: #000;
}

.form-content .page-links a:after {
  background-color: rgba(255, 255, 255, 0.5);
}

.form-content .page-links a.active:after {
  background-color: #fff;
}

.form-content .page-links a:hover:after,
.form-content .page-links a:focus:after {
  background-color: #fff;
}

.form-content input,
.form-content .dropdown-toggle.btn-default {
  border: 1px solid #ddd;
  background-color: #fff;
  color: #8D8D8D;
}

.form-content input:hover,
.form-content input:focus,
.form-content .dropdown-toggle.btn-default:hover,
.form-content .dropdown-toggle.btn-default:focus {
  border: 0;
  background-color: #ebeff8;
  color: #8D8D8D;
}

.form-content textarea {
  border: 0;
  background-color: #fff;
  color: #8D8D8D;
}

.form-content textarea:hover,
.form-content textarea:focus {
  border: 0;
  background-color: #ebeff8;
  color: #8D8D8D;
}

.form-content .custom-file-label {
  border: 0;
  background-color: #fff;
  color: #8D8D8D;
}

.form-content .custom-file-label:after {
  color: #0093FF;
}



.ibtn {
  background-color: #FFAF00;
  color: #fff;
  -webkit-box-shadow: 0 0 0 rgb(0 0 0 / 16%);
  box-shadow: 0 0 0 rgb(0 0 0 / 16%);
  width: 100%;
  height: 48px;
}

.ibtn:hover,
.ibtn:focus {
  background-color: #222;
  color: #fff;
  -webkit-box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16);
}

.form-content .form-button a {
  color: #000;
}

.form-content .other-links span {
  color: #000;
}

.form-content .other-links a {
  color: #000;
}

.form-content .form-sent .tick-holder .tick-icon {
  background-color: rgba(255, 255, 255, 0);
}

.form-content .form-sent .tick-holder .tick-icon:before {
  background-color: #fff;
}

.form-content .form-sent .tick-holder .tick-icon:after {
  background-color: #fff;
}

.form-content .form-sent h3 {
  color: #fff;
}

.form-content .form-sent p {
  color: #fff;
}

.form-content .form-sent .info-holder {
  color: #fff;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.form-content .form-sent .info-holder span {
  color: #fff;
}

.form-content .form-sent .info-holder a {
  color: #fff;
}

@keyframes tick-anime3 {
  0% {
    background-color: rgba(255, 255, 255, 0);
    -webkit-transform: rotate(35deg) scale(2);
    -moz-transform: rotate(35deg) scale(2);
    -ms-transform: rotate(35deg) scale(2);
    transform: rotate(35deg) scale(2);
  }

  100% {
    background-color: rgba(255, 255, 255, 0.2);
    -webkit-transform: rotate(45deg) scale(1);
    -moz-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
}

.alert {
  color: #fff;
}

.alert.alert-primary {
  background-color: rgba(226, 240, 255, 0.1);
  border-color: #fff;
}

.alert.alert-primary hr {
  border-top-color: #fff;
}

.alert.alert-secondary {
  background-color: rgba(240, 240, 240, 0.1);
  border-color: #fff;
}

.alert.alert-secondary hr {
  border-top-color: #fff;
}

.alert.alert-success {
  background-color: rgba(247, 255, 240, 0.1);
  border-color: #fff;
}

.alert.alert-success hr {
  border-top-color: #fff;
}

.alert.alert-danger {
  background-color: rgba(255, 250, 250, 0.1);
  border-color: #fff;
}

.alert.alert-danger hr {
  border-top-color: #fff;
}

.alert.alert-warning {
  background-color: rgba(255, 248, 225, 0.1);
  border-color: #fff;
}

.alert.alert-warning hr {
  border-top-color: #fff;
}

.alert.alert-info {
  background-color: rgba(220, 237, 241, 0.1);
  border-color: #fff;
}

.alert.alert-info hr {
  border-top-color: #fff;
}

.alert.alert-light {
  background-color: rgba(254, 254, 254, 0.1);
  border-color: #fff;
}

.alert.alert-light hr {
  border-top-color: #fff;
}

.alert.alert-dark {
  background-color: rgba(214, 216, 217, 0.1);
  border-color: #fff;
}

.alert.alert-dark hr {
  border-top-color: #fff;
}

.alert.with-icon.alert-primary:before {
  color: #fff;
}

.alert.with-icon.alert-secondary:before {
  color: #fff;
}

.alert.with-icon.alert-success:before {
  color: #fff;
}

.alert.with-icon.alert-danger:before {
  color: #fff;
}

.alert.with-icon.alert-warning:before {
  color: #fff;
}

.alert.with-icon.alert-info:before {
  color: #fff;
}

.alert.with-icon.alert-light:before {
  color: #fff;
}

.alert.with-icon.alert-dark:before {
  color: #fff;
}

.alert a,
.alert a.alert-link {
  color: #fff;
}

.alert .close {
  color: #fff;
}

.alert .close span {
  color: #fff;
}

.form-subtitle {
  color: #fff;
}

.rad-with-details .more-info {
  color: #fff;
}

.form-body.without-side h3 {
  color: #000;
}

.form-body.without-side p {
  color: #000;
}

.form-body.without-side label {
  color: #000;
}

.form-body.without-side .img-holder .info-holder img {
  display: none;
}

.form-body.without-side .form-content .form-items {
  padding: 35px 30px;
  background-color: #fff;
}

.form-body.without-side .form-content .form-items .other-links .text {
  color: #000;
}

.form-body.without-side .form-content .form-items .other-links a {
  color: #000;
  background-color: #F7F7F7;
}

.form-body.without-side .form-content .page-links a {
  color: #000;
}

.form-body.without-side .form-content .page-links a:after {
  background-color: rgba(255, 255, 255, 0.5);
}

.form-body.without-side .form-content .page-links a.active:after {
  background-color: #fff;
}

.form-body.without-side .form-content .page-links a:hover:after,
.form-body.without-side .form-content .page-links a:focus:after {
  background-color: #fff;
}

.form-body.without-side .form-content input,
.form-body.without-side .form-content .dropdown-toggle.btn-default {
  border: 0;
  background-color: #fff;
  color: #8D8D8D;
}

.form-body.without-side .form-content input:hover,
.form-body.without-side .form-content input:focus,
.form-body.without-side .form-content .dropdown-toggle.btn-default:hover,
.form-body.without-side .form-content .dropdown-toggle.btn-default:focus {
  border: 0;
  background-color: #fff;
  color: #8D8D8D;
}

.form-body.without-side .form-content .form-button .ibtn {
  background-color: #fff;
  color: #29A4FF;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.16);
}

.form-body.without-side .form-content .form-button .ibtn:hover,
.form-body.without-side .form-content .form-button .ibtn:focus {
  -webkit-box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16);
}

.form-body.without-side .form-content .form-button a {
  color: #fff;
}

/* -----------------------------------
    2 - Responsive Styles
------------------------------------*/
@media (max-width: 992px) {
  .form-holder {
    margin-left: 0;
  }

  .website-logo {
    top: 50px;
    left: 50px;
    right: initial;
    bottom: initial;
  }

  .website-logo .logo {
    background-image: url("../../public/images/hammer-1.png");
  }

  .form-body.without-side .website-logo .logo {
    background-image: url("../../public/images/hammer-1.png");
  }
}

/* CSS For Header */
.navigation {
  transition: .3s all;
}

.navigation .logo-default {
  display: none;
}

.navigation .navbar-light .navbar-nav .nav-item .nav-link {
  color: #fff;
  font-size: 20px;
  line-height: 26px;
  padding: 20px 15px;
  letter-spacing: 1px;
  transition: .2s ease-in-out 0s;
}

.navigation .navbar-light .navbar-nav .nav-item .nav-link:hover,
.navigation .navbar-light .navbar-nav .nav-item .nav-link:active,
.navigation .navbar-light .navbar-nav .nav-item .nav-link:focus {
  background: none;
  color: #28ABE3;
}

a.call_action {
  color: #fff;
  font-size: 20px;
}

.navigation .navbar-light .navbar-nav .nav-item.active .nav-link {
  color: #FEB84D;
}

@media (max-width: 991px) {
  .navigation .nav-link {
    padding: 10px !important;
  }
}

.navigation .dropdown:hover .dropdown-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.navigation .dropdown-menu {
  border: 0;
  border-radius: 0;
  top: 80%;
  left: 5px;
  display: block;
  visibility: hidden;
  transition: .3s ease;
  opacity: 0;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: top;
  transform-origin: top;
  background: #000;
}

@media (max-width: 991px) {
  .navigation .dropdown-menu {
    display: none;
    opacity: 1;
    visibility: visible;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: unset;
    transform-origin: unset;
    text-align: center;
  }
}

.navigation .dropdown-menu.show {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

@media (max-width: 991px) {
  .navigation .dropdown-menu.show {
    display: block;
  }
}

.navigation .dropdown-item {
  color: rgba(255, 255, 255, 0.8);
  transition: .2s ease;
  padding: 10px;
}

.navigation .dropdown-item:hover {
  color: #fff;
  background: transparent;
  padding-left: 12px;
}

@media (max-width: 991px) {
  .navigation {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
    background: #fff;
  }

  .navigation .navbar-light .navbar-nav .nav-item .nav-link {
    color: #000;
  }

  .navigation .logo-white {
    display: none;
  }

  .navigation .logo-default {
    display: block;
  }

  .navigation .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5) !important;
    border-color: rgba(0, 0, 0, 0.1) !important
  }
}

.sticky-header {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
  background: #fff;
}

.sticky-header .navbar-light .navbar-nav .nav-item .nav-link {
  color: #000;
}

.sticky-header .logo-white {
  display: none;
}

.sticky-header .logo-default {
  display: block;
}

.navbar-brand {
  height: auto;
  padding: 8px 0;
}

.navbar-brand h1 {
  margin: 0;
}

/* Header css complete */
/* CSS For Footer */
.text-white {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  text-decoration: none !important;
}

.top-footer {
  background-color: #272E37;
  border-top: 1px solid #404040;
  padding: 50px 0 40px;
}

.top-footer h3 {
  font-size: 16px;
  line-height: 1.5;
  color: #FAD481;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 20px;
}

.social_media li a {
  width: 30px;
  height: 30px;
  text-align: center;
  background: #1D46F6;
  border-radius: 50px;
  color: #fff !important;
}

.top-footer p {
  font-size: 13px;
  line-height: 30px;
  color: #bdbdbd;
  font-weight: 300;
  letter-spacing: 1px;
  /* padding-right: 20px; */
}

.top-footer li a {
  font-size: 15px;
  line-height: 30px;
  color: #bdbdbd;
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: capitalize;
  transition: color .3s;
  font-family: "Source Sans Pro", sans-serif;
  display: block;
  text-decoration: none
}

.top-footer li a:hover {
  color: #FAD481;
}

.footer-bottom {
  text-align: center;
  background-color: #1b1b1b;
  border-top: 1px solid #2c2c2c;
  padding: 15px 0;
}

.footer-bottom h5 {
  font-size: 14px;
  line-height: 1;
  color: #888888;
  font-weight: 400;
  letter-spacing: 1px;
  margin-top: 7px;
  margin-bottom: 3px;
}

.footer-bottom h6 {
  font-size: 11px;
  line-height: 15px;
  color: #888888;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 2px;
  margin-bottom: 0;
}

.footer-bottom a {
  color: #FFAF00;
  text-decoration: none;
}

.page-404 {
  display: flex;
  align-items: center;
  height: 100vh;
}

.page-404 h1 {
  font-size: 200px;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* Footer css complete */

.pagination>.active>a {
  background-color: #47ccde;
  border-color: #47ccde;
  color: #fff;
}

.pagination>li>a {
  border: 1px solid #adb5bd;
  padding: 4px 15px;
  outline: none;
  cursor: pointer;
  margin: 3px;
  text-transform: uppercase;
  border-radius: 5px;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  background-color: #147cde;
  border-color: #147cde;
  outline: none;

}

.pagination>li>a,
.pagination>li>span {
  color: #47ccde
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #F1F6FA;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
  border-radius: 2px
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: .5rem 1rem;
  /* border-bottom: 1px solid #ddd; */
}

.topbar #sidebarToggleTop {
  height: 2.5rem;
  width: 2.5rem;
}

.topbar .navbar-search input {
  font-size: .85rem;
  height: auto;
  padding: 0.9rem;
  border-radius: 15px !important;
}

.navbar-nav .nav-link:hover a {
  color: #0677aa;
  text-decoration: none;
}

.topbar.navbar-light .navbar-nav .nav-item .nav-link:hover {
  color: #39cdd4;
}

@media screen and(max-width:1200px) {
  .table-responsive {
    overflow-x: auto;
  }
}

@media screen and (min-width:1170px) {
  .topbar .navbar-search {
    width: 20rem;
  }

  .table-responsive {
    overflow-x: unset !important;
  }

  #userDropdown {
    padding-right: 0;
  }

  #userDropdown .mr-2.d-none.d-lg-inline.text-dark.font-weight-bold {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }
}

@media screen and (max-width:767px) {
  #responsive-navbar-nav {
    position: absolute;
    top: 3.5rem;
    background-color: #fff;
    width: 100%;
    left: 0;
    padding: 15px;
    border-top: 1px solid #ddd;
  }
}

.circle-c {
  width: 21px;
  height: 21px;
  border-radius: 20px;
  background-color: #C4C4C4;
  margin-top: 3px;
  border-color: #FFF;
  border-width: 2px;
  border-style: solid;
}

.circle-c .count-circle {
  margin-left: 4px;
  margin-top: 2px;
  font-size: 10px;
  color: #FFF;
}

.loader_block {
  position: fixed;
  display: block;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100% !important;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.7);
}

.loader_block span {
  left: 50%;
  top: 50%;
  position: relative;
}

.loader_block_css {
  /* position: fixed; */
  /* display: block; */
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100% !important;
  /* z-index: 9999; */
  background-color: transparent;
}

.back-btn {
  background-color: rgba(109, 109, 110, 0.64);
  /* border-color: #000; */
  /* border-width: 2px; */
  /* border-style: solid; */
  height: auto;
  display: flex;
  justify-content: center;
  width: auto;
  border-radius: 6px;
  padding: 5px 10px;
  max-width: 100px;
  text-align: center;
}

img.img-fluid.profile_img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border: 1px dashed #3b3f44;
  box-shadow: none;
}

.pdf-modal .modal.show .modal-dialog {
  transform: none;
  max-width: 1000px;
}

.sticky {
  position: -webkit-sticky;
  position: sticky !important;
  top: 40px;
  display: flex;
  flex-flow: column;
}

section.benefits_section {
  position: relative;
  overflow: hidden;
  margin-top: 4rem;
  padding: 3rem 0px;
}

.benefits_section .bg-Image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

h5.bottomheading {
  margin: 10px 15px;
  font-weight: 700;
}

section.benefits_section .container {
  position: relative;
}

.player {
  background-color: #fff;
  padding: 8px;
  margin-bottom: 1rem;
}

.player video {
  margin-left: 5%;
}

.bottom-hr {
  border-bottom: #ccd1d5 1px solid;
}

.no-data-img {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.no-data-img-2 {
  width: 30%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.no-data-img-3 {
  width: 40%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.form-control {
  box-shadow: none;
}

@media screen and (max-width: 480px) {
  .full-width {
    /* min-height: 100vh; */
    height: auto;
    margin-top: 40px;
  }
}