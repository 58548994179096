/* CSS For TheamHeader */
.TheamHeader .navigation {
  transition: .3s all;
  background: #272E37;
  position: fixed;
}

.navigation .logo-default {
  display: none;
}

.navigation .navbar-light .navbar-nav .nav-item .nav-link {
  color: #fff;
  font-size: 14px;
  line-height: 26px;
  padding: 20px 15px;
  letter-spacing: 1px;
  transition: .2s ease-in-out 0s;

}

.navigation .navbar-light .navbar-nav .nav-item .nav-link:hover,
.navigation .navbar-light .navbar-nav .nav-item .nav-link:active,
.navigation .navbar-light .navbar-nav .nav-item .nav-link:focus {
  background: none;
  color: #f5f5f5;
}

a.call_action {
  color: #fff;
  font-size: 16px;
}

.navigation .navbar-light .navbar-nav .nav-item.active .nav-link {
  color: #FEB84D;
}

@media (max-width: 991px) {
  .navigation .nav-link {
    padding: 10px !important;
  }
}

.navigation .dropdown:hover .dropdown-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.navigation .dropdown-menu {
  border: 0;
  border-radius: 0;
  top: 80%;
  left: 5px;
  display: block;
  visibility: hidden;
  transition: .3s ease;
  opacity: 0;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: top;
  transform-origin: top;
  background: #000;
}

@media (max-width: 991px) {
  .navigation .dropdown-menu {
    display: none;
    opacity: 1;
    visibility: visible;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: unset;
    transform-origin: unset;
    text-align: center;
  }
}

.navigation .dropdown-menu.show {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

@media (max-width: 991px) {
  .navigation .dropdown-menu.show {
    display: block;
  }
}

.navigation .dropdown-item {
  color: rgba(255, 255, 255, 0.8);
  transition: .2s ease;
  padding: 10px;
}

.navigation .dropdown-item:hover {
  color: #fff;
  background: transparent;
  padding-left: 12px;
}

@media (max-width: 991px) {
  .navigation {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
    background: #fff;
  }

  .navigation .navbar-light .navbar-nav .nav-item .nav-link {
    color: #000;
  }

  .navigation .logo-white {
    display: none;
  }

  .navigation .logo-default {
    display: block;
  }

  .navigation .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5) !important;
    border-color: rgba(0, 0, 0, 0.1) !important
  }
}

.sticky-header {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
  background: #272E37;
  color: #ffff
}

.sticky-header .navbar-light .navbar-nav .nav-item .nav-link {
  color: #ffff
}

.sticky-header .logo-white {
  display: none;
}

.sticky-header .logo-default {
  display: block;
}

.navbar-brand {
  height: auto;
  padding: 8px 0;
  width: 20%;
}

.navbar-brand img {
  width: 100%;
}

.navbar-brand h1 {
  margin: 0;
}


/* TheamHeader css complete */