.privacypolicy .containerA {
    position: relative;
    justify-content: center;
    background: #F4F6F8;

}

.containerA .row .d-block {
    width: 679px;
    height: 444px;
    left: 1064px;
    top: 118px;
}

.containerA .row .column {
    margin-left: 200px !important;
}

.containerA .row .col-lg-6 .display {

    /* position: absolute; */
    /* width: 416px; */
    height: 90px;
    left: 374px;
    /* top: 229px; */

    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 90px;

    /* identical to box height */

    color: #000000;
}

.containerA .row .col-lg-6 span {
    color: #FFAF00;
}

.containerA .row .col-lg-6 .lead {
    /* position: absolute; */
    width: 500px;
    height: 134px;
    left: 374px;
    top: 340px;

    /* font-family: 'Poppins'; */
    /* font-style: normal; */
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;

    color: #000000;
}

.privacypolicy .containerB {
    margin-top: 48px;
    max-width: 90%;
}

/* .containerB .row .col-sm-3 i{
    
position: absolute;
left: 0%;
right: 0%;
top: 0%;
bottom: 0%;

background: #000000;
} */
.privacypolicy .containerB .row .col-sm-6 .Text {
    /* position: absolute; */
    width: 506px;
    height: 48px;
    left: 424px;
    margin-top: 48px;

    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 48px;

    /* identical to box height */

    color: #000000;
}

.privacypolicy .containerB .row .col-sm-6 .Textparagraph {
    color: #3E3E3E;
    font-size: 15px;
}

.privacypolicy .containerB .row .col-sm-6 .container .row .col-sm-4 .rounded {

    /* position: absolute; */
    margin-left: 36%;
    right: 69.48%;
    top: 47.07%;
    bottom: 51.19%;

    /* background: #000000; */
    justify-items: center;

}

.privacypolicy .containerB .row .col-sm-6 .container .row .col-sm-4 h2 {
    /* position: absolute; */
    height: 41px;
    left: 26.3%;
    right: 67.71%;
    top: calc(50% - 41px/2 - 1.5px);

    font-family: 'Cormorant Garamond';
    font-style: normal;
    /* font-weight: 600; */
    font-size: 24px;
    line-height: 41px;
    text-align: center;
    /* identical to box height, or 171% */
    letter-spacing: 1.2px;

    color: #000000;
}

.privacypolicy .containerB .row .col-sm-6 .container .row .col-sm-4 p {
    /* position: absolute; */
    left: 22.08%;
    right: 63.59%;
    top: 50.94%;
    bottom: 45.85%;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;

    /* or 164% */
    text-align: center;

    color: #666666;
}

@media (max-width: 992px) {
    .containerA .row .d-block{height: auto !important;}
    .containerA .row .column{margin-left: 0px !important;}
    .containerA .row .col-lg-6 .lead{max-width: 100%;}
    .containerB .row .col-sm-6 .Text {
        position: relative !important;
        left: auto !important;
        margin-top: 10px !important;
        height: auto !important;
        width: auto !important;
    }
    .privacypolicy .containerB{    padding: 15px;max-width: 100%; margin: 0;}
}