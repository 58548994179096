.card-body .rightside {
    background-image: url("../components/images/Rectangle\ 261.jpg");
    width: "300px";
    height: "540px";
    border-radius: "15px";
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #FFF;
    background-color: #FFAF00;
    /* border-color: #dee2e6 #dee2e6 #fff; */
    /* margin-left: 15px; */
    width: 175px;
    padding-left: 20px;
    border-radius: 10px 10px 0px 0px;
}

.nav-tabs .nav-link {
    margin-bottom: -1px;
    background-color: #E3EBF1;
    border: 1px solid transparent;
    border-radius: 10px 10px 0px 0px;
    color: #4B4B4D;
    width: 175px;
    margin-right: 10px;
    padding-left: 20px;
}

.nav-tabs {
    border-bottom: 5px solid #FFAF00;
    /* margin-top: 1px; */
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    /* color: #495057;
    background-color: #fff; */
    border-color: transparent;
}

.search-box {
    width: 90%;
    height: 45px;
    border-radius: 30px;
    background-color: #E3EBF1;
    margin-left: auto;
    margin-right: auto;

}

.searchinput {
    border: transparent;
    background: #E3EBF1;
    width: 90%;
    height: 45px;
    margin-left: 20px;
    /* margin-bottom: auto; */
}

.result {
    color: #787878;
    font-size: 15px;

}

.button-go {
    background-color: #0D99FF;
    border-radius: 30px;
    margin-left: 50px;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-go .goto {
    /* margin-right: auto; */
    font-size: 13px;
    color: #FFF;
}

.para {
    width: 90%;
    font-size: 15px;
    line-height: 25px;
}

.bounce-in .timeline-item-date {
    position: absolute;
    top: -12px;
    left: 0;
    background: #DEF0FD;
    /* padding: 1px; */
    clip-path: none !important;
    height: 52px;
    box-sizing: border-box;
    width: 50px;
    border-radius: 30px;
}

.bounce-in .timeline-item-date .timeline-item-dateinner {
    background: #DEF0FD;
    border-radius: 30px;
    color: black;
    padding: 0;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    /* border-right-color: transparent; */
    clip-path: none !important;
    height: 50px;
    width: 100%;
    display: block;
    line-height: 52px;
    text-indent: 15px;
}

.timeline::before {
    content: "";
    position: absolute;
    top: 0px;
    left: calc(8% + 6px) !important;
    bottom: 0px;
    width: 0px;
    border: 1px solid !important;


}

.entry .title::before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border: 4px solid;
    background-color: #ffffff;
    border-radius: 100%;
    top: 15%;
    left: 62px !important;
    z-index: 99;
    box-sizing: content-box;
}

.body-container {
    position: relative;
    margin-left: -150px !important;
    width: 90% !important;
}

.timeline--wrapper {
    width: calc(100% - 24px);
    padding: 0px !important;
}

.timeline {
    width: 100%;
    max-width: 800px;
    padding: 15px 0 0 0;
    position: relative;
    /* box-shadow: 0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2); */
    margin: 20px auto !important;
}

.add-note-btn {
    width: 75%;
    height: 40px;
    border-radius: 5px;
    background-color: #FFAF00;
    /* margin-left: auto;
    margin-right: auto; */
    padding-top: 8px;
    padding-left: 25px;
}

.video-add-note {
    border-radius: 25px;
    background-color: #ffcc5d;
}

/* .video-css iframe{
    pointer-events:initial;
} */
.para .YourHighlightClass {
    background-color: #ffff00;
}

.notFound {
    padding: 30px;
    text-align: center;
    font-size: 20px;
}

.pdf-btn {
    display: flex;
    justify-content: space-around;
}

.pdf-btn-css {
    display: flex;
    justify-content: end;
}

.pdf-modal canvas {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.search-result {}

/* .search-pdf {
    width: 50%;
}

*/

.left {
    width: 60%;
}

.left {
    /* position: fixed; */
}

.right {
    padding-left: 60%;
    position: fixed;
}

.left img {
    height: auto;
    max-width: 100%;
    outline: 0;
}

.searchinput:focus {
    outline: none;
}

.chat-main {
    width: 100%;
    height: auto;
    background-color: #fff;
}

.chat-box {
    /* width: 60%; */
    /* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2); */
    border-radius: 0px 14px 14px 0px;
    height: auto;
    /* background-color: #FFFFFF; */
    margin: auto;
    margin-top: 10px;
    padding-bottom: 20px;
    /* padding: 20px; */
}

.chat-img {
    width: 50px;
    height: 50px;
    /* background-color: #fff; */
    contain: content;
}

.chat-img .chat {
    object-fit: cover;
    width: 100%;
    height: auto;
}

.chat-head {
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 37px;

    color: #000000;
}

.head-row-chat {
    height: 15%;
    width: 103%;
    padding: 10px 0px 10px 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
    border-radius: 14px 14px 0px 0px;
}

.date-row {
    border-bottom: 2px solid #F1F1F1;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
}

.cell-width {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 320px;
}

.date-row-text {
    border-radius: 53px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -1%;
    text-align: center;
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    /* text-transform: capitalize; */
    color: #000000;
    line-height: 14px;
    background: #fff;
    width: 35%;
    height: 25px;
}

.chat-msgs {
    max-height: 450px;
    overflow: scroll;
    height: 420px;
}

.chat-text-area {
    width: 100%;
    background: #FFFFFF;

    /* Grey-02 */
    border: 2px solid #C2C2C2;
    border-radius: 14px;
    /* height: 135px; */
    position: relative;
    contain: content;
}

.chat-text-area .form-control-input {
    border: none;
}

.chat-text-area .form-control-input:focus {
    box-shadow: none;
    height: 60px;
}

.send-box {
    width: 100%;
    background-color: red;
    height: auto;
    bottom: 0;
    background: #F5F5F5;
    border-radius: 0px 0px 14px 14px;
}

.chat-area {
    resize: none;
}

.display-end-css {
    display: flex;
    justify-content: end;
    padding: 10px 20px 10px 0px;
}

.send-chat {
    background: #0237E2;
    width: 20%;
    padding: 7px 10px 5px 10px;
    /* Shadow01 */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 14px;
    /* margin-top: 10px; */
    text-align: center;

    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    cursor: pointer;
    /* identical to box height, or 133% */

    /* White */
    color: #FFFFFF;
}

.clear-box {
    background: #0237E2;
    width: 55%;
    padding: 7px 10px 5px 10px;
    background: rgba(156, 156, 156, 0.22);
    border: 1px solid #7081B9;
    border-radius: 10px;
    text-align: center;

    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    cursor: pointer;
    color: #3F4660;
}

.get-msg-box {
    width: 100%;
    height: auto;
    padding: 10px;
    border-bottom: 1px solid #F1F1F1;
}

.get-msg-head {
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;

    /* identical to box height, or 125% */

    /* Black */
    color: #202020;
}

.get-msg-head span {
    /* font-family: 'Open Sans'; */
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-left: 20px;
    /* Gray 2 */
    color: #4F4F4F;
}

.get-msg-sub {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    /* identical to box height, or 143% */
    text-align: left;
    /* Black */
    color: #202020;
    width: 90%;
    margin-right: auto;
}

.send-msg-box {
    width: 100%;
    height: auto;
    padding: 10px;
    border-bottom: 1px solid #F1F1F1;
    padding: 5px 10px 10px 10px;
    border-bottom: 1px solid #F1F1F1;
    /* margin-bottom: 2%; */
}

.send-msg-head {
    font-family: 'Cairo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    /* identical to box height, or 125% */

    /* Black */
    color: #202020;
}

.send-msg-head span {
    /* font-family: 'Open Sans'; */
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-right: 20px;
    /* Gray 2 */
    color: #4F4F4F;
}

.send-msg-sub {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    /* identical to box height, or 143% */
    text-align: right;
    /* Black */
    color: #202020;
    width: 90%;
    margin-left: auto;
}

@media only screen and (min-device-width: 750px) and (max-device-width: 950px) {
    .chat-box {
        width: 80%;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 500px) {
    .chat-box {
        width: 95%;
    }

    .get-col {
        padding: 0px;
    }

    .chat-head {
        font-size: 17px;
        line-height: 21px;
    }

    .date-row-text {
        margin-top: -3%;
        width: 50%;
    }

    .head-row-chat {
        width: 115%;
    }

    .get-msg-box,
    .send-msg-box {
        padding: 5px;
    }

    .get-msg-sub,
    .send-msg-sub {
        font-size: 13px;
        line-height: 17px;
        width: 100%;
    }

    .display-end {
        padding: 10px 10px 10px 0px;
    }

    .send-chat {
        width: 40%;
    }

    .clear-box {
        width: 60%;
    }
}


.help-badge {
    height: 4.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    margin: auto;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999
}

.help-badge .badge-content {
    width: 11.5rem;
    height: 3rem;
    display: flex;
    align-items: center;
    background-color: #fff;
    color: #5a5a5a;
    padding: 0 2.25rem 0 1.25rem;
    margin-right: -2.25rem;
    border-radius: .5rem;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .1);
    flex: 1 1 auto;
}

.text-truncate {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.badge-image-container {

    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .1);
    border: .25rem solid #fff;
    border-radius: 50%;
    width: 72px;
    height: 72px;
    background-color: #4a4a4a;
    flex-shrink: 0;

}

.badge-image-container .badge-image {
    max-width: 100%;
    max-height: 100%;
    font-size: 30px;
    color: #fff;
}

@media screen and (max-width: 480px) {

    .searchinput {
        width: 80%;
    }

    .search-pdf {
        margin-top: 25px;
    }

    .textPrcess {
        font-size: 16px !important;
        margin-left: 0px !important;
    }

    .download-icon {
        margin-left: -10px !important
    }

    .result {
        color: #787878;
        font-size: 15px;
        margin-left: -15px !important;
        width: 50% !important;
    }

    .button-go {
        width: 85px;
        height: 35px !important;
    }

    .para {
        width: 95% !important;
        font-size: 15px !important;
        line-height: 23px;
        margin-left: -15px !important;
    }
}