.sub-noti {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #A5A5A5;
}

.cell-box {
  width: 20%;
  padding: 5px 20px;
  height: 100%;
  background-color: #F6EEFF;
  border-radius: 14px;
  color: #2F80ED;
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  margin-top: 10px;
  /* text-align: ; */
}

.card-noti {
  background-color: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 14px;
  padding: 15px;
  width: 93%;
  height: auto;
  margin-left: auto;
  margin-right: auto;

}

.img-border {
  border: 2px solid rgba(29, 70, 246, 0.64);
  border-radius: 14px;
  height: 65px;
  width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-circle img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.img-circle {
  border-radius: 50px;
  height: 55px;
  width: 55px;
  /* background-color: black; */
  object-fit: cover;
}

.date-noti {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  /* identical to box height */

  /* Grey-01 */
  color: #A5A5A5;
}

.img-col {
  display: flex;
  justify-content: center;
}

.desc-noti {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;

  /* identical to box height */

  /* Black */
  color: #202020;
}

.title-noti {
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;

  /* identical to box height */

  /* Black */
  color: #202020;
}

.btn-col {
  display: flex;
  justify-content: end;
  align-items: center;
}

.btn-col i {
  color: #A5A5A5;
  font-size: 18px;
}

.desc-noti span {
  color: #1D46F6;
  font-family: 'Cairo';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}

.no-data-noti {
  display: flex;
  justify-content: center;
}