.course__title {
  margin: 2rem 0px;
}

.card_details {
  background-color: #f7f7f7;
  padding: 15px 0px 20px;
  border: 1px solid #ddd;
  border-radius: 2px;
}

tfoot.bg-gray-totoal {
  background-color: #f7f7f7;
  border: 1px solid #ddd;
}

.order-summary__section {
  margin-top: 14%;
}

.form-control {
  min-height: 30px;
}

.course__title h4 {
  color: #0284bc;
}

.product__image {
  width: 125px !important;
}

.product-table {
  margin-top: 0.75em;
  position: relative;
  z-index: 1;
}

small.disabale {
  color: #666;
  font-size: 14px;
}

.product:first-child td {
  padding-top: 0;
  width: 100px;
}

.product__description {
  text-align: left;
  padding-left: 12px;

}

.product-thumbnail__wrapper .product-thumbnail__image {
  border-radius: 8px;
  border: 1px rgba(0, 0, 0, 0.1) solid;
  z-index: 2;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-table tr.product td {
  padding: 10px 0px;
}

.total-line__price .order-summary__small-text {
  font-size: 0.8571428571em;
  color: #717171;
  display: flex;
  justify-content: end;
}

td.total-line__price .order-summary {
  color: #323232;
  font-weight: 600;
  display: flex;
  justify-content: end;
}

.btn-warning-css {
  background-color: #d4aa32;
  color: #fff;
}

span.payment-due__price {
  color: #323232;
  font-size: 1.7142857143em;
  font-weight: 500;
  letter-spacing: -0.04em;
  line-height: 1em;
}

span.payment__currency {
  color: #717171;
  font-size: 0.8571428571em;
  vertical-align: 0.2em;
  margin-right: 0.5em;
}

td:last-child,
th:last-child {
  /* text-align: right; */
}

table.total-line-table tr td,
th {
  padding: 5px;
}

th.total-line__name {
  font-weight: 500;
  color: #666;
}

th.total-line__name {
  font-weight: 500;
  color: #666;
  width: 350px;
}

th.total-line__name-disc {
  font-weight: 500;
  color: #666;
  /* padding-left: 150px; */
  /* padding-bottom: 70px; */
}

th.total-line__name-disc span {
  padding-right: 120px;
}

.coupon-check {
  padding-right: 200px;
  color: #6e6e6e;
}

.check-btn::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: -4px;
  left: 6px;
  background: #fff;
  border-radius: inherit;
  transform: translate3d(0, 0.75em, -1em);
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
}

.black_line_check::before {
  border: 1px solid #87e5eb;
  background-color: #c4fcff;
}

.check-btn:hover {
  background: #b4ebee;
  color: #000;
  border-color: #fff;
  transform: translate(0, 0.25em);
}

.check-btn:active {
  background: #ffffff;
  transform: translate(0em, 0.75em);
  left: 0px;
}

.check-btn:active::before {
  /* box-shadow: 0 0 0 2px #b18597, 0 0 #ffe3e2; */
  transform: translate3d(0, 0, -1em);
  left: 0px;
}

.check-btn {
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  /* padding: 0.7em 1em; */
  background: #26C9D3;
  border: 1px solid #26C9D3;
  border-radius: 0.05em;
  transform-style: preserve-3d;
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms cubic-bezier(0, 0, 0.58, 1);
  display: inline-block;
  position: relative;
  min-width: 120px;
  border-radius: 0px !important;
}

table {
  width: 100%;
}

.order-summary__section {
  position: relative;
  height: 100%;
  padding: 20px;
}

.order-summary__section::before {
  content: "";
  display: block;
  width: 300%;
  position: absolute;
  top: -20px;
  bottom: 0;
  background: #fafafa;
  z-index: -1;
  -webkit-box-shadow: 0 -1px 0 #e1e1e1 inset;
  box-shadow: 0 -1px 0 #e1e1e1 inset;
  left: 0;
  height: 102%;
}


@media screen and (min-width: 1024px) {
  .m-ml-2 {
    margin-left: -1rem;
  }
}

.apply-coupon-box {
  height: 30px;
  margin-left: 25px;
  border-radius: 5px;
  background-color: #ffc107;
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 6px 6px 6px 10px;
  width: 100%;
}

.apply-code {
  font-size: 12px;
  font-weight: 600;
  /* margin-left: 10%; */
  /* margin-top: -2%; */

}