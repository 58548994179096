.ContactUs .containerA {
    position: relative;
    justify-content: center;
    background: #F4F6F8;

}
.image1{
    margin-bottom:5%;
    width:100%;
    height:500px
}
.rightside{
    border-radius: 1px solid grey;
   
}
.border{
    padding: 25px;
    border-radius: 15px;
}
.pushbtn{
    color: bisque;
    background:#f1a808;
    border: none;
    padding: 6px 35px;
    border-radius: 6px;
    
}
